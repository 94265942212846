@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@700&family=Barlow:wght@400;500&display=swap);
@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

@keyframes show-navigation {
  from {
    opacity: 0;
    transform: translateY(-300px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hide-navigation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav {
    padding-top: 20px;
  }
}

.blesk-full-nav, .blesk-full-nav *, .blesk-full-nav:before, .blesk-full-nav:after, .blesk-full-nav :before, .blesk-full-nav :after {
  box-sizing: border-box;
}

@media (max-width: 1099px) {
  .blesk-full-nav__container {
    width: 768px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__container {
    width: 100%;
    max-width: 480px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__input--search {
    width: 100%;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__link-with-icon {
    font-size: 15px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__link-with-icon img {
    margin-left: 15px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon img {
    margin-right: 10px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__header {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__header {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__search {
    flex: 1;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__search {
    order: 1;
    min-width: 100%;
    margin: 20px 0 0;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__main {
    float: left;
    margin-right: 35px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__main {
    order: 1;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__main {
    width: 745px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__main {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__list-section {
    min-width: 160px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list {
    flex-wrap: wrap;
    display: flex;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__lists-group {
    min-width: 355px;
    margin-bottom: -35px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group .blesk-full-nav__list-section {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-item {
    width: 50%;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-link {
    font-size: 15px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--projects {
    margin-bottom: 10px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar {
    float: right;
    width: 160px;
    padding-top: 25px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--specials {
    margin-bottom: 30px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-group {
    margin-bottom: 40px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group {
    display: flex;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group--other {
    margin-bottom: 15px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-group--other {
    margin-right: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-list {
    flex-wrap: wrap;
    display: flex;
  }

  .blesk-full-nav__sidebar-list--projects {
    margin-right: -30px;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__sidebar-list--projects {
    margin-right: 0;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__premium-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__premium-link {
    margin-right: 30px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-right: 30px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__zazrak-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__zazrak-link {
    margin-right: 30px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
    width: auto;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__bazar-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-right: 10px;
  }

  .blesk-full-nav__sidebar-specials-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-projects-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-projects-item {
    align-items: center;
    width: 25%;
    padding-right: 20px;
    display: flex;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-projects-item {
    width: calc(50% - 10px);
    padding-right: 0;
  }

  .blesk-full-nav__sidebar-projects-item:nth-child(odd) {
    margin-right: 20px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__footer {
    margin-top: 30px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__footer {
    flex-direction: column;
    order: 1;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__footer {
    flex-flow: column wrap;
    align-items: center;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__socials {
    order: 2;
    margin-bottom: 32px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__contacts {
    order: 2;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications {
    flex-direction: column;
    order: 1;
    align-items: center;
    margin-bottom: 32px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-titles {
    margin-right: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-subtitle {
    text-align: center;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-stores {
    margin-top: 32px;
  }
}

.blesk-header, .blesk-header *, .blesk-header:before, .blesk-header:after, .blesk-header :before, .blesk-header :after {
  box-sizing: border-box;
}

@media (min-width: 1100px) {
  .blesk-header--responsive {
    min-width: 1080px;
    height: 125px;
    padding-top: 0 !important;
  }
}

@media (max-width: 1099px) {
  .blesk-header--responsive {
    height: auto;
  }
}

@media (max-width: 767px) {
  .blesk-header__top {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .blesk-header__bottom {
    background-color: #fff;
    height: 44px;
  }
}

@media (min-width: 1100px) {
  .blesk-header--full-width .blesk-header__container {
    width: auto;
    min-width: 1110px;
    max-width: 1620px;
    padding: 0 30px;
  }

  .blesk-header__container {
    width: 1080px;
  }
}

@media (min-width: 1100px) {
  .blesk-header__magazines {
    height: 34px;
  }
}

@media (max-width: 1099px) {
  .blesk-header__magazines {
    display: none;
  }

  .blesk-header--breaking-visible .blesk-header__magazines {
    display: block;
  }
}

@media (max-width: 1099px) {
  .blesk-header__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header__inner {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    justify-content: space-between;
    padding: 8px 0;
  }
}

@media (max-width: 767px) {
  .blesk-header__logo {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header__day-info {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .blesk-header__day-info {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .blesk-header__day-info {
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .blesk-header__date {
    padding-right: 10px;
  }

  .blesk-header__name-day {
    border-left: 1px solid #fff;
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .blesk-header__weather {
    margin-right: 30px;
  }
}

@media (min-width: 768px) {
  .blesk-header__daily-feed {
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-header__login {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav .blesk-header__login {
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-header__login-image {
    width: 22px;
    height: 22px;
  }

  .blesk-header-nav .blesk-header__login-image {
    order: 1;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .blesk-header__search-icon {
    display: none;
  }

  .body--article .blesk-header__top .cnc-menu nav, .body--article .blesk-header__inner, .body--article .blesk-header-nav__menu-list, .body--article .blesk-header__search-icon, .body--article .blesk-header-nav__ikiosek-link, .body--article .blesk-header-nav__premium-link {
    display: none !important;
  }
}

@media (min-width: 1100px) {
  .blesk-header-magazines .cnc-menu {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }
}

@media (max-width: 959px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    height: 75px !important;
  }
}

@media (max-width: 321px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    max-height: 94px;
  }
}

@media (max-width: 1099px) {
  .blesk-header-nav {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav {
    background-color: #ed1c24;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__logo {
    min-width: 182px;
    height: 74px;
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__logo {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button:before {
    color: #fff;
    width: 20px;
    height: 3px;
    box-shadow: 0 6px, 0 -6px;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__hamburger-button {
    margin-right: 25px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button {
    order: -1;
    width: 14px;
    font-size: 0;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__menu-list {
    display: none;
  }
}

@media (max-width: 1099px) {
  .blesk-header-nav__menu-item:nth-child(n+8) {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-sticky-header .blesk-header-nav__search-icon {
    display: block;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__premium-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__premium-link {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__tipovacka-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__tipovacka-link {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__zazrak-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__zazrak-link {
    margin-right: 18px;
  }
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-logo {
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-menu-list {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item.blesk-header-nav__menu-item--hidden, .blesk-sticky-header .subcategory-menu__list ~ .blesk-header-nav__menu-list {
  display: none;
}

@media (min-width: 768px) {
  .blesk-sticky-header {
    padding-left: 0;
  }
}

.blesk-sticky-header, .blesk-sticky-header *, .blesk-sticky-header:before, .blesk-sticky-header:after, .blesk-sticky-header :before, .blesk-sticky-header :after {
  box-sizing: border-box;
}

.clear {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

@font-face {
  font-family: tablet_gothic_condensed;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

.blesk-logo {
  background-color: #ed1c24;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-logo__image {
  display: block;
}

.blesk-full-nav {
  z-index: 10002;
  color: #fff;
  opacity: 0;
  background-color: rgba(0, 0, 0, .95);
  width: 100%;
  height: 100%;
  padding: 80px 0 30px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

@media (max-width: 1099px) {
  .blesk-full-nav {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav {
    padding-top: 20px;
  }
}

.blesk-full-nav, .blesk-full-nav *, .blesk-full-nav:before, .blesk-full-nav:after, .blesk-full-nav :before, .blesk-full-nav :after {
  box-sizing: border-box;
}

.blesk-full-nav--show {
  opacity: 1;
  animation: .2s ease-in-out show-navigation;
  display: block;
}

.blesk-full-nav--hide {
  opacity: 0;
  animation: .2s ease-in-out hide-navigation;
}

.blesk-full-nav a {
  color: inherit;
}

.blesk-full-nav a:focus {
  outline: none;
}

.blesk-full-nav__container {
  width: 960px;
  margin: 0 auto;
  padding: 0 10px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__container {
    width: 768px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__container {
    width: 100%;
    max-width: 480px;
  }
}

.blesk-full-nav__input {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  padding: 0 15px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.blesk-full-nav__input:focus {
  outline: none;
}

.blesk-full-nav__input--search {
  width: 550px;
  height: 48px;
  padding-right: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__input--search {
    width: 100%;
  }
}

.blesk-full-nav__link-with-icon {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.blesk-full-nav__link-with-icon:hover {
  text-decoration: underline;
}

.blesk-full-nav__link-with-icon:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__link-with-icon {
    font-size: 15px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__link-with-icon img {
    margin-left: 15px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__link-with-icon img {
    margin-right: 10px;
  }
}

.blesk-full-nav__header {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__header {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__header {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.blesk-full-nav__header .logo-2024 svg {
  width: auto;
  height: 48px;
}

.blesk-full-nav__logo {
  width: 136px;
  height: 48px;
}

.blesk-full-nav__logo:focus {
  outline: none;
}

.blesk-full-nav__search {
  margin: auto auto auto 60px;
  position: relative;
}

@media (max-width: 1099px) {
  .blesk-full-nav__search {
    flex: 1;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__search {
    order: 1;
    min-width: 100%;
    margin: 20px 0 0;
  }
}

.blesk-full-nav__search-icon {
  opacity: .65;
  cursor: pointer;
  background: none;
  border: none;
  width: 18px;
  height: 18px;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.blesk-full-nav__search-icon:focus {
  outline: none;
}

.blesk-full-nav__close-button {
  cursor: pointer;
  will-change: transform;
  will-change: opacity;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0;
  transition: transform .2s ease-in-out;
  display: flex;
}

.blesk-full-nav__close-button:hover {
  opacity: .8;
}

.blesk-full-nav__close-button:active {
  opacity: .6;
}

.blesk-full-nav__close-button:hover {
  transform: rotate(90deg);
}

.blesk-full-nav__close-button:focus {
  outline: none;
}

.blesk-full-nav__close-button svg {
  width: 25px;
  height: 25px;
}

.blesk-full-nav__close-button path {
  fill: #fff;
}

.blesk-full-nav__main {
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__main {
    float: left;
    margin-right: 35px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__main {
    order: 1;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__main {
    width: 745px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__main {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .blesk-full-nav__list-section {
    min-width: 160px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section {
    width: 100%;
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-section:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-section:last-child {
    margin-bottom: 0;
  }
}

.blesk-full-nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .blesk-full-nav__list {
    flex-wrap: wrap;
    display: flex;
  }
}

.blesk-full-nav__lists-group {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-full-nav__lists-group {
    min-width: 355px;
    margin-bottom: -35px;
    margin-right: 35px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group {
    flex-direction: column;
  }
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section {
  margin-bottom: 35px;
}

.blesk-full-nav__lists-group .blesk-full-nav__list-section:nth-child(2n) {
  margin-right: 0;
}

@media (max-width: 767px) {
  .blesk-full-nav__lists-group .blesk-full-nav__list-section {
    margin-bottom: 30px;
  }
}

.blesk-full-nav__list-title {
  color: #fff;
  border-bottom: 2px solid #ed1c24;
  margin: 0 0 15px;
  padding: 0 0 5px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__list-title a {
  text-decoration: none;
  color: inherit !important;
}

.blesk-full-nav__list-title a:hover {
  text-decoration: underline;
}

.blesk-full-nav__list-item {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-item {
    width: 50%;
    margin-bottom: 15px;
  }
}

.blesk-full-nav__list-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__list-link {
  text-decoration: none;
}

.blesk-full-nav__list-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-full-nav__list-link {
    font-size: 15px;
  }
}

.blesk-full-nav__sidebar--projects {
  clear: right;
  margin-bottom: 30px;
  padding-top: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--projects {
    margin-bottom: 10px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar {
    float: right;
    width: 160px;
    padding-top: 25px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar {
    flex-wrap: wrap;
    width: 100%;
    display: flex;
  }
}

.blesk-full-nav__sidebar--specials {
  margin-bottom: 40px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar--specials {
    margin-bottom: 30px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-group {
    margin-bottom: 40px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group {
    display: flex;
  }
}

.blesk-full-nav__sidebar-group:last-child {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-group--other {
    margin-bottom: 15px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-group--other {
    margin-right: 0;
  }
}

.blesk-full-nav__sidebar-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-list {
    flex-wrap: wrap;
    display: flex;
  }

  .blesk-full-nav__sidebar-list--projects {
    margin-right: -30px;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__sidebar-list--projects {
    margin-right: 0;
  }
}

.blesk-full-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 20px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
}

@media (min-width: 1100px) {
  .blesk-full-nav__premium-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__premium-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__stardance-tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: 108px 18px;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 18px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
  box-shadow: 0 0 2px rgba(245, 239, 220, .2), 0 0 6px rgba(252, 244, 219, .6);
}

@media (min-width: 1100px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__stardance-tipovacka-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__zazrak-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9274978_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: 63px 19px;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 19px;
  padding-top: 5px;
  font-size: 0;
  display: flex;
  position: static;
  box-shadow: 0 0 2px rgba(245, 239, 220, .2), 0 0 6px rgba(252, 244, 219, .6);
}

@media (min-width: 1100px) {
  .blesk-full-nav__zazrak-link {
    margin-bottom: 20px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__zazrak-link {
    margin-right: 30px;
  }
}

.blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
  justify-content: flex-start;
  text-decoration: none;
  position: relative;
}

.blesk-full-nav__ikiosek-link:hover, .blesk-full-nav__bazar-link:hover {
  text-decoration: underline;
}

@media (max-width: 1099px) {
  .blesk-full-nav__ikiosek-link, .blesk-full-nav__bazar-link {
    width: auto;
  }
}

.blesk-full-nav__bazar-link {
  margin-top: 20px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__bazar-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-right: 10px;
  }

  .blesk-full-nav__sidebar-specials-item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-specials-item {
    margin-bottom: 10px;
  }
}

.blesk-full-nav__sidebar-specials-item:last-child {
  margin-bottom: 0;
}

.blesk-full-nav__sidebar-specials-link {
  background-color: #fff;
  border-radius: 2px;
  padding: 0 15px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
  display: inline-block;
  color: #1e1e1e !important;
}

.blesk-full-nav__sidebar-specials-link:hover {
  text-decoration: underline;
}

.blesk-full-nav__sidebar-projects-item {
  margin-bottom: 20px;
}

@media (min-width: 1100px) {
  .blesk-full-nav__sidebar-projects-item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__sidebar-projects-item {
    align-items: center;
    width: 25%;
    padding-right: 20px;
    display: flex;
  }
}

@media (max-width: 767px) {
  .blesk-full-nav__sidebar-projects-item {
    width: calc(50% - 10px);
    padding-right: 0;
  }

  .blesk-full-nav__sidebar-projects-item:nth-child(odd) {
    margin-right: 20px;
  }
}

.blesk-full-nav__footer {
  border-top: 1px solid rgba(255, 255, 255, .5);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  display: flex;
}

@media (min-width: 1100px) {
  .blesk-full-nav__footer {
    margin-top: 30px;
  }
}

@media (max-width: 1099px) {
  .blesk-full-nav__footer {
    flex-direction: column;
    order: 1;
  }
}

@media (max-width: 575px) {
  .blesk-full-nav__footer {
    flex-flow: column wrap;
    align-items: center;
  }
}

.blesk-full-nav__socials {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__socials {
    order: 2;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__socials-list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-full-nav__socials-item {
  margin-right: 15px;
}

.blesk-full-nav__socials-item:last-child {
  margin-right: 0;
}

.blesk-full-nav__socials-link {
  will-change: opacity;
  justify-content: center;
  align-items: center;
  display: flex;
}

.blesk-full-nav__socials-link:hover {
  opacity: .8;
}

.blesk-full-nav__socials-link:active {
  opacity: .6;
}

.blesk-full-nav__socials-link:focus {
  outline: none;
}

.blesk-full-nav__contacts {
  align-items: center;
  font-size: 15px;
  line-height: 17px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__contacts {
    order: 2;
  }
}

.blesk-full-nav__contacts-phone {
  border-left: 1px solid #fff;
  margin-left: 10px;
  padding-left: 10px;
}

.blesk-full-nav__applications {
  align-items: center;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications {
    flex-direction: column;
    order: 1;
    align-items: center;
    margin-bottom: 32px;
  }
}

.blesk-full-nav__applications-titles {
  flex-direction: column;
  margin-right: 12px;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-titles {
    margin-right: 0;
  }
}

.blesk-full-nav__applications-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
}

.blesk-full-nav__applications-title > a {
  text-decoration: none;
}

.blesk-full-nav__applications-title > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-subtitle {
    text-align: center;
  }
}

.blesk-full-nav__applications-subtitle > a {
  text-decoration: none;
}

.blesk-full-nav__applications-subtitle > a:hover {
  text-decoration: underline;
}

.blesk-full-nav__applications-stores {
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-full-nav__applications-stores {
    margin-top: 32px;
  }
}

.blesk-full-nav__applications-google {
  margin-right: 8px;
  line-height: 0;
}

.blesk-full-nav__applications-apple {
  line-height: 0;
}

.blesk-header {
  z-index: 10001;
  color: #fff;
  width: 100%;
  min-width: 1080px;
  height: 126px;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

.blesk-header, .blesk-header *, .blesk-header:before, .blesk-header:after, .blesk-header :before, .blesk-header :after {
  box-sizing: border-box;
}

.blesk-header--responsive {
  min-width: 0;
}

@media (min-width: 1100px) {
  .blesk-header--responsive {
    min-width: 1080px;
    height: 125px;
    padding-top: 0 !important;
  }
}

@media (max-width: 1099px) {
  .blesk-header--responsive {
    height: auto;
  }
}

.blesk-header img {
  display: block;
}

.blesk-header a:hover {
  color: inherit;
}

.blesk-header a:focus {
  outline: none;
}

.blesk-header__link {
  color: #fff;
  text-decoration: none;
}

.blesk-header__link:not([href]):hover {
  text-decoration: none;
}

.blesk-header__top {
  background-color: #ed1c24;
}

@media (max-width: 767px) {
  .blesk-header__top {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.blesk-header__bottom {
  background-color: #ed1c24;
}

@media (min-width: 768px) {
  .blesk-header__bottom {
    background-color: #fff;
    height: 44px;
  }
}

.blesk-header__container {
  margin: 0 auto;
}

@media (min-width: 1100px) {
  .blesk-header--full-width .blesk-header__container {
    width: auto;
    min-width: 1110px;
    max-width: 1620px;
    padding: 0 30px;
  }

  .blesk-header__container {
    width: 1080px;
  }
}

.blesk-header__magazines {
  height: 0;
}

@media (min-width: 1100px) {
  .blesk-header__magazines {
    height: 34px;
  }
}

@media (max-width: 1099px) {
  .blesk-header__magazines {
    display: none;
  }

  .blesk-header--breaking-visible .blesk-header__magazines {
    display: block;
  }
}

.blesk-header__magazines--hide {
  display: none !important;
}

.blesk-header__inner {
  align-items: center;
  padding: 4px 0 0;
  display: flex;
}

@media (max-width: 1099px) {
  .blesk-header__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header__inner {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    justify-content: space-between;
    padding: 8px 0;
  }
}

.blesk-header__logo {
  margin: 4px 30px 10px 0;
}

@media (max-width: 767px) {
  .blesk-header__logo {
    display: none;
  }
}

.blesk-header__day-info {
  margin-right: auto;
  display: flex;
}

@media (min-width: 768px) {
  .blesk-header__day-info {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .blesk-header__day-info {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .blesk-header__day-info {
    font-size: 11px;
  }
}

.blesk-header__day-info a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .blesk-header__date {
    padding-right: 10px;
  }

  .blesk-header__name-day {
    border-left: 1px solid #fff;
    padding-left: 10px;
  }
}

.blesk-header__weather {
  color: #fff;
  will-change: opacity;
  align-self: stretch;
  align-items: center;
  padding: 0 10px 0 30px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__weather:hover {
  opacity: .8;
}

.blesk-header__weather:active {
  opacity: .6;
}

.blesk-header__weather:hover {
  text-decoration: none;
}

.blesk-header__weather:after {
  content: "";
  z-index: 1;
  border-top: 2px solid #fff;
  border-bottom: none;
  border-left: none;
  border-right: 2px solid #fff;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%)rotate(45deg);
}

@media (min-width: 768px) {
  .blesk-header__weather {
    margin-right: 30px;
  }
}

.blesk-header__weather-icon {
  height: 26px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.blesk-header__daily-feed {
  will-change: opacity;
}

.blesk-header__daily-feed:hover {
  opacity: .8;
}

.blesk-header__daily-feed:active {
  opacity: .6;
}

@media (min-width: 768px) {
  .blesk-header__daily-feed {
    display: none;
  }
}

.blesk-header-nav .blesk-header__daily-feed {
  width: 22px;
  height: 22px;
  margin-inline-start: auto;
  margin-inline-end: 16px;
  position: relative;
}

.blesk-header-nav .blesk-header__daily-feed svg {
  width: 100%;
  height: 100%;
}

.blesk-header__login {
  color: #fff;
  will-change: opacity;
  flex-direction: column;
  align-items: center;
  max-height: 18px;
  font-size: 9px;
  line-height: 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

.blesk-header__login:hover {
  opacity: .8;
}

.blesk-header__login:active {
  opacity: .6;
}

.blesk-header__login--desktop {
  margin-top: -2px;
}

.blesk-header__login:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .blesk-header__login {
    display: none;
  }
}

.blesk-header-nav .blesk-header__login {
  color: #fff;
  flex-direction: row;
  max-height: none;
  font-size: 11px;
  font-weight: 400;
  display: flex;
}

.blesk-header-nav .blesk-header__login:hover {
  color: #fff;
}

@media (min-width: 768px) {
  .blesk-header-nav .blesk-header__login {
    display: none;
  }
}

.blesk-header__login:has(.blesk-header__login-text--initials) {
  text-align: center;
  color: red;
  background: #fff;
  border-radius: 51%;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-weight: 700;
  line-height: 18px;
}

.blesk-header__login:has(.blesk-header__login-text--initials):hover {
  color: red;
  text-decoration: none;
}

.blesk-header__login-image {
  border-radius: 51%;
  margin-bottom: 2px;
}

@media (max-width: 767px) {
  .blesk-header__login-image {
    width: 22px;
    height: 22px;
  }

  .blesk-header-nav .blesk-header__login-image {
    order: 1;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 0;
  }
}

.blesk-header__search-icon {
  cursor: pointer;
  will-change: opacity;
  background: none;
  border: none;
  min-width: 18px;
  margin-left: 15px;
  padding: 0;
  transition: opacity .2s linear;
}

.blesk-header__search-icon:hover {
  opacity: .8;
}

.blesk-header__search-icon:active {
  opacity: .6;
}

.blesk-header__search-icon:focus {
  outline: none;
}

@media (max-width: 767px) {
  .blesk-header__search-icon {
    display: none;
  }

  .body--article .blesk-header__top .cnc-menu nav, .body--article .blesk-header__inner, .body--article .blesk-header-nav__menu-list, .body--article .blesk-header__search-icon, .body--article .blesk-header-nav__ikiosek-link, .body--article .blesk-header-nav__premium-link {
    display: none !important;
  }
}

.blesk-header-magazines .cnc-menu {
  height: 34px;
  margin-bottom: 0;
  line-height: 34px;
  box-shadow: none !important;
}

.blesk-header-magazines .cnc-menu:after {
  content: none !important;
}

@media (min-width: 1100px) {
  .blesk-header-magazines .cnc-menu {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }
}

.blesk-header-magazines .cnc-menu nav {
  z-index: auto;
  background: none;
  width: auto;
  min-height: auto;
  top: auto;
  left: auto;
}

.blesk-header-magazines .cnc-menu li ul {
  top: 34px;
}

.blesk-header-magazines .cnc-menu li ul li a {
  padding: 0;
}

.blesk-header-magazines .cnc-menu__list {
  align-items: center;
  display: flex;
}

.blesk-header-magazines .cnc-menu__list:after {
  content: none;
}

.blesk-header-magazines .cnc-menu__list a {
  padding: 0 20px;
  color: rgba(255, 255, 255, .6) !important;
  cursor: pointer !important;
  border: none !important;
  border-right: 1px solid rgba(0, 0, 0, .2) !important;
  margin: 0 !important;
}

.blesk-header-magazines .cnc-menu__list a:hover {
  color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:hover:before, .blesk-header-magazines a[href*="javascript"]:hover:after {
  background-color: #fff !important;
}

.blesk-header-magazines a[href*="javascript"]:before, .blesk-header-magazines a[href*="javascript"]:after {
  pointer-events: none !important;
  background-color: rgba(255, 255, 255, .6) !important;
}

.blesk-header-magazines li:last-child a {
  border-right: none !important;
}

.blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
  height: 34px !important;
}

@media (max-width: 959px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    height: 75px !important;
  }
}

@media (max-width: 321px) {
  .blesk-header-magazines .breaking-news:not(.breaking-news--mobile) {
    max-height: 94px;
  }
}

.blesk-header-nav {
  color: #1e1e1e;
  will-change: transform;
  background-color: #fff;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav {
    background-color: #ed1c24;
    justify-content: space-between;
  }
}

.blesk-header-nav a {
  color: inherit;
  font-family: inherit;
}

.blesk-header-nav a:focus {
  outline: none;
}

@media (min-width: 768px) {
  .blesk-header-nav__logo {
    min-width: 182px;
    height: 74px;
    display: none;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__logo {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.blesk-sticky-header .blesk-header-nav__logo {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-right: 30px;
  display: flex;
}

.blesk-header-nav__hamburger-button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  padding: 0 0 0 17px;
  position: relative;
}

.blesk-header-nav__hamburger-button:focus {
  outline: none;
}

.blesk-header-nav__hamburger-button:before {
  content: "";
  color: #1e1e1e;
  background-color: currentColor;
  width: 13px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  box-shadow: 0 4px, 0 -4px;
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button:before {
    color: #fff;
    width: 20px;
    height: 3px;
    box-shadow: 0 6px, 0 -6px;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__hamburger-button {
    margin-right: 25px;
  }
}

@media (max-width: 767px) {
  .blesk-header-nav__hamburger-button {
    order: -1;
    width: 14px;
    font-size: 0;
  }
}

.blesk-header-nav__menu-list {
  align-items: center;
  margin: 0 auto 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.blesk-sticky-header .blesk-header-nav__menu-list {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .blesk-header-nav__menu-list {
    display: none;
  }
}

.blesk-header-nav__menu-item {
  margin-right: 23px;
}

.blesk-sticky-header .blesk-header-nav__menu-item {
  white-space: nowrap;
  order: 0;
}

.blesk-sticky-header .blesk-header-nav__menu-item--show {
  visibility: visible;
}

.blesk-sticky-header .blesk-header-nav__menu-item--hidden {
  visibility: hidden;
  order: 1;
  display: block;
}

.blesk-header-nav__menu-item:last-child {
  margin-right: 0;
}

@media (max-width: 1099px) {
  .blesk-header-nav__menu-item:nth-child(n+8) {
    display: none;
  }
}

.blesk-header-nav__menu-link {
  text-decoration: none;
}

.blesk-header-nav__menu-link:hover {
  text-decoration: underline;
}

.blesk-header-nav__menu-link.menu-link_soutez {
  color: #000;
  text-transform: uppercase;
  background-color: #ffcf00;
  padding: 5px;
}

.blesk-header-nav__menu-link.menu-link_soutez:hover {
  color: #000;
}

.blesk-header-nav__search-icon {
  display: none;
}

@media (min-width: 768px) {
  .blesk-sticky-header .blesk-header-nav__search-icon {
    display: block;
  }
}

.blesk-header-nav__premium-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2");
  background-repeat: no-repeat;
  background-size: contain;
  width: 83px;
  min-width: 83px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

.blesk-sticky-header .blesk-header-nav__premium-link {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .blesk-header-nav__premium-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__premium-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__tipovacka-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9202230_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: contain;
  width: 108px;
  min-width: 108px;
  height: 18px;
  margin-left: 15px;
  position: static;
}

@media (max-width: 767px) {
  .blesk-header-nav__tipovacka-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__tipovacka-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__zazrak-link {
  background-color: rgba(0, 0, 0, 0);
  background-image: url("https://img.cncenter.cz/img/1/origin/9274978_v0.jpg?v=0");
  background-repeat: no-repeat;
  background-size: contain;
  width: 63px;
  min-width: 63px;
  height: 19px;
  margin-left: 15px;
  position: static;
}

@media (max-width: 767px) {
  .blesk-header-nav__zazrak-link {
    display: none;
  }
}

@media (min-width: 768px) {
  .blesk-header-nav__zazrak-link {
    margin-right: 18px;
  }
}

.blesk-header-nav__secondary-logo {
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-logo {
  color: inherit;
  flex-shrink: 0;
  margin-left: 15px;
  margin-right: 20px;
  padding-top: 5px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 26px;
  line-height: 29px;
  display: block;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-logo {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-logo img {
  margin: -5px 0 0;
  display: block;
}

.blesk-header-nav__secondary-menu-list {
  margin-right: auto;
  display: none;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list {
  width: 100%;
  height: auto;
  display: flex;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .blesk-sticky-header .blesk-header-nav__secondary-menu-list {
    display: none;
  }
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu {
  overflow-x: unset;
}

.blesk-sticky-header .blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item.blesk-header-nav__menu-item--hidden, .blesk-sticky-header .subcategory-menu__list ~ .blesk-header-nav__menu-list {
  display: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item {
  color: #000;
  margin-right: 25px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:last-child {
  margin-right: 0;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--hidden {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item--with-submenu:hover {
  background: none;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__item:not(.subcategory-menu__item--with-submenu) {
  padding-right: 0;
}

.blesk-sticky-header .subcategory-menu__item {
  display: block;
}

.blesk-header-nav__secondary-menu-list .subcategory-menu__link {
  font-size: inherit;
  line-height: inherit;
  border: none;
  padding: 0;
}

.blesk-header-nav__secondary-menu-list--with-open-submenu .subcategory-menu__item--with-submenu:hover .subcategory-menu__submenu {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.blesk-sticky-header {
  z-index: 9999;
  align-items: center;
  min-height: auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
}

@media (min-width: 768px) {
  .blesk-sticky-header {
    padding-left: 0;
  }
}

.blesk-sticky-header, .blesk-sticky-header *, .blesk-sticky-header:before, .blesk-sticky-header:after, .blesk-sticky-header :before, .blesk-sticky-header :after {
  box-sizing: border-box;
}

.widget-tv-program {
  background-color: #fff;
  margin-top: 40px;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;
}

.widget-tv-program__complete-program {
  color: #fff;
  background: #e86b3d;
  padding: 12px 35px 12px 20px;
  font-size: 13px;
  text-decoration: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.widget-tv-program__complete-program:after {
  content: "";
  border: 4px solid rgba(0, 0, 0, 0);
  border-left: 5px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  top: 17px;
  right: 26px;
}

.widget-tv-program__complete-program:hover {
  background: #d84d1a;
  text-decoration: underline;
}

.widget-tv-program__buttons-group {
  margin: 50px 0 20px;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .widget-tv-program__buttons-group input.widget-tv-program__buttons-group-toggle-checkbox[type="checkbox"]:checked ~ .widget-tv-program__buttons-group-toggle-btn .line1 {
    transform: rotate(45deg);
  }

  .widget-tv-program__buttons-group input.widget-tv-program__buttons-group-toggle-checkbox[type="checkbox"]:checked ~ .widget-tv-program__buttons-group-toggle-btn .line2 {
    transform: scaleY(0);
  }

  .widget-tv-program__buttons-group input.widget-tv-program__buttons-group-toggle-checkbox[type="checkbox"]:checked ~ .widget-tv-program__buttons-group-toggle-btn .line3 {
    transform: rotate(-45deg);
  }

  .widget-tv-program__buttons-group input.widget-tv-program__buttons-group-toggle-checkbox[type="checkbox"]:checked ~ .widget-tv-program__buttons-group-menu {
    right: 0 !important;
  }
}

.widget-tv-program__buttons-group-menu {
  background: violet;
  margin: 0;
  list-style-type: none;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .widget-tv-program__buttons-group-menu {
    z-index: 4;
    background-color: #fff;
    width: 200px;
    padding: 8px;
    transition: all .7s;
    position: absolute;
    top: 40px;
    right: -300px;
    box-shadow: 1px 1px 5px -2px rgba(0, 0, 0, .8);
  }
}

.widget-tv-program__buttons-group-menu li {
  float: right;
}

@media only screen and (max-width: 768px) {
  .widget-tv-program__buttons-group-menu li {
    float: none;
    width: 100%;
  }
}

.widget-tv-program__buttons-group-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .widget-tv-program__buttons-group-toggle {
    left: calc(100% - 30px);
  }
}

.widget-tv-program__buttons-group-toggle-btn {
  z-index: 2;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 26px;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .widget-tv-program__buttons-group-toggle-btn {
    display: none;
  }
}

.widget-tv-program__buttons-group-toggle-btn .line {
  background: #0e2431;
  border-radius: 10px;
  width: 100%;
  height: 4px;
  display: block;
}

.widget-tv-program__buttons-group-toggle-btn .line1 {
  transform-origin: 0 0;
  transition: transform .4s ease-in-out;
}

.widget-tv-program__buttons-group-toggle-btn .line2 {
  transition: transform .2s ease-in-out;
}

.widget-tv-program__buttons-group-toggle-btn .line3 {
  transform-origin: 0 100%;
  transition: transform .4s ease-in-out;
}

.widget-tv-program__buttons-group-toggle-checkbox {
  z-index: 5;
  opacity: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin: 0;
  display: block;
  position: absolute;
}

@media only screen and (min-width: 768px) {
  .widget-tv-program__buttons-group-toggle-checkbox {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .widget-tv-program__carousel-left, .widget-tv-program__carousel-right {
    display: none;
  }
}

.widget-tv-program__button-tip {
  color: #000;
  background-color: #fff;
  border: 2px solid #dadce1;
  border-radius: 6px;
  margin: 5px 10px 0 0;
  padding: 6px 15px;
  font-size: 13px;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .widget-tv-program__button-tip {
    border: none;
    border-radius: 0;
    width: 100%;
    margin: 0;
    display: block;
  }
}

.widget-tv-program__button-tip:hover {
  text-decoration: underline;
}

.widget-tv-program__button-tip.selected {
  color: #fff;
  background-color: #e86b3d;
  border-color: #e86b3d;
}

.widget-tv-program__button-tip.selected:hover {
  background: #d84d1a;
}

.widget-tv-program__buttons-group-title {
  float: left;
  margin-right: 40px;
  font-size: 20px;
  font-weight: bold;
}

.widget-tv-program__carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .widget-tv-program__carousel {
    overflow: auto;
  }
}

.widget-tv-program__carousel-wrapper {
  width: 10000px;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.widget-tv-program__carousel-right {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  width: 132px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.widget-tv-program__carousel-right.hidden {
  display: none;
}

.widget-tv-program__carousel-arrow-right {
  cursor: pointer;
  background: #000;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.widget-tv-program__carousel-arrow-right:after {
  content: "";
  border: 11px solid rgba(0, 0, 0, 0);
  border-left: 21px solid #fff;
  border-right-width: 0;
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 17px;
}

.widget-tv-program__carousel-left {
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  width: 132px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.widget-tv-program__carousel-left.hidden {
  display: none;
}

.widget-tv-program__carousel-arrow-left {
  cursor: pointer;
  background: #000;
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.widget-tv-program__carousel-arrow-left:after {
  content: "";
  border: 11px solid rgba(0, 0, 0, 0);
  border-left-width: 0;
  border-right: 21px solid #fff;
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  top: 15px;
  left: 12px;
}

.widget-tv-program__carousel-item-title {
  line-clamp: 2;
  font-weight: bold;
  overflow: hidden;
}

.widget-tv-program__carousel-item-box {
  position: absolute;
  bottom: 4px;
  left: 10px;
  right: 10px;
}

.widget-tv-program__carousel-item-box-logo {
  float: right;
  width: 25px;
  height: 25px;
}

.widget-tv-program__carousel-item-box-logoimg {
  width: 100%;
  height: auto;
}

.widget-tv-program__carousel-item-box-time {
  float: left;
  height: 25px;
  font-size: 11px;
}

.widget-tv-program__carousel-progress-box {
  background: #dadce1;
  width: 100%;
  height: 3px;
  margin-top: 3px;
  position: relative;
  overflow: hidden;
}

.widget-tv-program__carousel-progress-box-indicator {
  background: #6fc17c;
  height: 3px;
  position: relative;
}

.widget-tv-program__carousel-current-item {
  float: left;
  background-color: #f4f5f6;
  border: 1px solid #dadce1;
  width: 135px;
  height: 90px;
  margin: 0 20px 0 0;
  padding: 15px 10px;
  font-size: 13px;
  position: relative;
}

.widget-tv-program__carousel-current {
  height: 90px;
}

.widget-tv-program__carousel-tip-item {
  float: left;
  width: 170px;
  height: 335px;
  margin: 0 20px 0 0;
  font-size: 13px;
  position: relative;
}

.widget-tv-program__carousel-tip-item img {
  width: 100%;
  height: auto;
}

.widget-tv-program__carousel-tip-item .widget-tv-program__carousel-item-box-logo {
  float: left;
  width: 36px;
  height: 36px;
}

.widget-tv-program__carousel-tip-item .widget-tv-program__carousel-item-title {
  border-bottom: 1px solid #c6c6c6;
  height: 46px;
  margin-top: 10px;
  font-size: 15px;
}

.widget-tv-program__carousel-tip-item .widget-tv-program__carousel-item-box-time {
  margin-right: 5px;
  padding-top: 10px;
}

.widget-tv-program__carousel-tip {
  height: 335px;
  display: none;
}

.widget-tv-program__carousel-tip.selected {
  display: block;
}

.widget-tv-program__carousel-tip .widget-tv-program__carousel-arrow-left, .widget-tv-program__carousel-tip .widget-tv-program__carousel-arrow-right {
  top: 40%;
}

.widget-tv-program__mt-20 {
  margin-top: 20px;
}

.widget-tv-program__mb-20 {
  margin-bottom: 20px;
}

.widget-tv-program__clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
  height: auto;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.article-container.bigArticle .wrapper .articlePhoto a .loading-placeholder, .article-container.bigArticle .wrapper .articlePhoto a.autofun .loading-placeholder {
  height: 240px;
}

.article-container.middleArticle .wrapper a.articlePhoto .loading-placeholder, .article-container.smallArticle .wrapper a.articlePhoto .loading-placeholder {
  height: 180px;
}

.middle.bigArticle .wrapper .articlePhoto a .loading-placeholder, .middle.bigArticle .wrapper .articlePhoto a.autofun .loading-placeholder {
  height: 240px;
}

.middle.middleArticle .wrapper a.articlePhoto .loading-placeholder, .middle.smallArticle .wrapper a.articlePhoto .loading-placeholder {
  height: 180px;
}

.list-article-small .list-article-small-thumbnail .loading-placeholder {
  height: 90px;
}

.lazyload.loaded {
  opacity: 1;
}

.autoCZ.loading-placeholder-small .loading-placeholder {
  height: 85px;
}

.item-loaded-error {
  padding-bottom: 0 !important;
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

@font-face {
  font-family: "tablet_gothic_condensed", "Times New Roman", serif;
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
}

body {
  background-color: #e9eff4;
  font-family: arial, sans-serif;
}

#body-wrapper {
  background: inherit;
  padding-top: 20px;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.container {
  background-color: #e9eff4;
  min-height: 1000px;
  margin: 0 auto 40px;
  padding: 0 10px;
  display: block;
}

.container--footer {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 576px) {
  .container {
    width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media (min-width: 960px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1080px) {
  .container {
    width: 1080px;
    padding: 0 70px;
  }
}

.block {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .block {
    margin-bottom: 40px;
  }

  .block:empty {
    margin-bottom: 0;
  }
}

.visually-hidden {
  clip: rect(1px 1px 1px 1px) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  width: 1px !important;
  height: 1px !important;
  position: absolute !important;
  overflow: hidden !important;
}

.promobox {
  margin-bottom: 20px;
}

.promobox img {
  width: 100%;
}

.tabheader-articles--sport {
  display: flex;
}

#footer-global {
  padding-top: 20px !important;
}

#footer-global .footer-divider:first-of-type, #footer-global .footer-version-switcher {
  display: none !important;
}

#footer-global .cnc-selection {
  margin-top: 0 !important;
}

@media (min-width: 768px) {
  .corona-box {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

@media (min-width: 1320px) {
  .ad-no-desktop {
    display: none !important;
  }
}

@media (max-width: 959px) {
  .rekl322 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .magazine-links {
    flex-direction: column;
    margin-bottom: 0;
  }

  #footer-global .magazine-links__stores {
    margin: 0 48px 40px;
  }

  .magazine-links__stores-google {
    margin-right: 24px;
  }
}

.hot-topics {
  flex-wrap: wrap;
  margin-bottom: -10px;
  margin-right: -10px;
  display: flex;
}

@media (min-width: 768px) {
  .hot-topics {
    margin-bottom: -20px;
    margin-right: -20px;
  }
}

.hot-topics__inner {
  flex-direction: column;
  display: flex;
}

@media (max-width: 767px) {
  .hot-topics__inner {
    flex-direction: row;
    width: 100%;
  }
}

.hot-topics__bottom {
  width: 100%;
  display: flex;
}

.hot-topics__item {
  margin: 0 10px 10px 0;
}

.hot-topics__item--big, .hot-topics__item--vertical {
  width: 100%;
}

@media (min-width: 768px) {
  .hot-topics__item--vertical {
    width: 364px;
  }
}

@media (min-width: 960px) {
  .hot-topics__item--vertical {
    width: 460px;
  }
}

.hot-topics__item--small {
  width: calc(50% - 10px);
}

@media (min-width: 768px) {
  .hot-topics__item--small {
    width: 364px;
  }
}

@media (min-width: 960px) {
  .hot-topics__item--small {
    width: 460px;
  }
}

@media (max-width: 767px) {
  .hot-topics__item--bottom {
    width: calc(50% - 10px);
  }
}

@media (min-width: 768px) {
  .hot-topics__item {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

.hot-topic__inner {
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.hot-topic__inner:hover {
  text-decoration: underline;
}

.hot-topic__image-wrapper {
  display: block;
}

@media (min-width: 768px) {
  .hot-topic__image-wrapper {
    height: 100%;
  }
}

.hot-topic__image {
  width: 100%;
  height: auto;
  display: block;
}

@media (min-width: 768px) {
  .hot-topics__item--vertical .hot-topic__image {
    height: 100%;
  }
}

.hot-topic__title {
  color: #000;
  background: #fff;
  flex: 1;
  margin: 0;
  padding: 10px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 18px;
  line-height: 22px;
}

@media (min-width: 576px) {
  .hot-topic__title {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (min-width: 768px) {
  .hot-topic__title {
    clip: rect(1px 1px 1px 1px) !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    white-space: nowrap !important;
    width: 1px !important;
    height: 1px !important;
    position: absolute !important;
    overflow: hidden !important;
  }
}

.category-menu {
  color: #000;
  border-top: 3px solid;
  align-items: center;
  margin: 0 0 10px;
  padding-top: 5px;
  display: flex;
}

.category-menu--celebrity {
  color: red;
}

.category-menu--zpravy {
  color: #084185;
}

.category-menu--sport {
  color: #04a545;
}

.category-menu--style {
  color: #cb2f9b;
}

.category-menu--aha {
  color: red;
  margin-top: 40px;
}

.category-menu--video, .category-menu--premium {
  background-color: #fff;
  border-top: none;
  margin: 40px 0 0;
  padding: 5px 10px 0;
  position: relative;
  top: 2px;
}

@media (max-width: 575px) {
  .category-menu--video, .category-menu--premium {
    margin: 20px -10px 0;
  }
}

.category-menu__title {
  text-transform: uppercase;
  margin: 0 auto 0 0;
  padding-top: 5px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 20px;
  line-height: 33px;
}

@media (min-width: 576px) {
  .category-menu__title {
    padding-top: 8px;
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .category-menu__title {
    padding-top: 7px;
    font-size: 30px;
  }
}

.category-menu__logo-tvblesk {
  background-image: url("/images/blesk/tvblesk-logo.png");
  background-size: 86px 25px;
  width: 86px;
  height: 25px;
  display: block;
}

.category-menu__logo-premium {
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2") 0 0 / contain no-repeat;
  width: 107px;
  height: 23px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 0;
  line-height: 38px;
  text-decoration: none;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.category-menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.category-menu__item {
  margin-right: 20px;
  font-size: 13px;
  font-weight: 700;
  position: relative;
}

.category-menu__item:last-child {
  margin-right: 0;
  display: block;
}

.category-menu__item--with-arrow {
  padding-right: 10px;
  display: block !important;
}

.category-menu__item--with-arrow:after {
  content: "";
  border: 3px solid rgba(0, 0, 0, 0);
  border-left: 4px solid;
  border-right: 0 solid;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 959px) {
  .category-menu__item:nth-child(n+4) {
    display: none;
  }
}

@media (min-width: 576px) {
  .category-menu__item {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .category-menu__item {
    display: none;
  }
}

.category-menu__link {
  color: currentColor;
  text-decoration: none;
}

.category-menu__premium .category-menu__link {
  text-decoration: none;
}

.category-menu__link:hover {
  text-decoration: underline;
}

.category-menu--premium .category-menu__link:hover {
  text-decoration: none;
}

.articles--special {
  margin: 0 0 10px;
}

.articles--zpravy .articles__list .articles__list--recombee {
  order: 2;
  width: 100%;
  display: flex;
}

@media (max-width: 767px) {
  .articles--zpravy .articles__list .articles__list--recombee {
    order: 0;
    margin-bottom: 10px;
  }
}

.articles__list {
  flex-wrap: wrap;
  margin: 0 -10px -10px 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media (min-width: 768px) {
  .articles__list {
    margin-bottom: -20px;
    margin-right: -20px;
  }
}

.articles__list .recombee--contents {
  display: contents;
}

.articles--special .articles__list {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .articles--celebrity .articles__list, .articles--sport .articles__list {
    flex-direction: column;
    justify-content: space-between;
    height: 565px;
    margin-bottom: 0;
  }
}

@media (min-width: 960px) {
  .articles--celebrity .articles__list, .articles--sport .articles__list {
    height: 705px;
  }
}

.articles.with-bottom-space .articles__list {
  margin-bottom: 0;
}

.articles__item {
  width: calc(50% - 10px);
  margin: 0 10px 10px 0;
  display: flex;
}

.articles__item--zpravy {
  color: #084185;
}

@media (max-width: 767px) {
  .articles--regiony .articles__item:nth-child(3n) {
    width: 100%;
  }

  .articles--aha .articles__item:nth-child(3n) {
    width: 100%;
    height: 415px;
    max-height: 415px;
  }

  .articles--zpravy .articles__item:nth-child(3n), .articles--zpravy .articles__item:nth-child(3n) img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .articles--zpravy .articles__item:nth-child(n+3) {
    order: 2;
  }
}

.articles--zpravy .articles__item--latest-news {
  order: 1 !important;
}

@media (max-width: 767px) {
  .articles--zpravy .articles__item--latest-news {
    width: 100%;
    max-height: 250px;
  }

  .articles--lifestyle .articles__item:last-child {
    margin-bottom: 0;
  }

  .articles--lifestyle .articles__item {
    width: 100%;
    margin-bottom: 15px;
  }

  .articles.with-bottom-space .articles__item:last-child {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .articles__item {
    width: calc(33.3333% - 20px);
    max-height: 267px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 960px) {
  .articles__item {
    max-height: 342px;
  }
}

.articles__item--special {
  width: 100%;
  max-height: none;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .articles__item--special:nth-child(n+4) {
    margin-top: 10px;
  }
}

.articles__item--special:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .articles__item--special:only-child {
    flex-grow: 0;
    flex-basis: 50%;
  }

  .articles__item--special {
    width: calc(33.333% - 20px);
    margin-bottom: 0;
  }
}

.articles__item--celebrity {
  color: red;
}

.articles__item--celebrity:nth-child(n+3) {
  order: 1;
}

@media (min-width: 768px) {
  .articles__item--celebrity {
    flex-basis: calc(50% - 10px);
    margin-bottom: 0;
  }
}

.articles__item--aha {
  color: red;
  height: 365px;
  max-height: 365px;
}

.articles__item--aha .article-preview__top {
  top: 40px;
}

.articles__item--aha .article-preview__sticker {
  height: 30px;
  position: absolute;
  top: -30px;
}

.articles__item--aha .article-preview__title {
  height: 170px;
  position: relative;
  top: 35px;
}

@media (max-width: 767px) {
  .articles__item--aha {
    height: 305px;
  }
}

@media (max-width: 959px) {
  .articles__item--aha {
    height: 305px;
  }
}

@media (max-width: 767px) {
  .articles__item--vertical-promobox {
    order: 1;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .articles__item--vertical-promobox {
    height: 100%;
    max-height: none;
    margin-bottom: 0;
  }
}

.articles__item--sport {
  color: #04a545;
}

@media (min-width: 768px) {
  .articles__item--sport {
    flex-basis: calc(50% - 10px);
    margin-bottom: 0;
  }
}

.articles__item--style {
  color: #cb2f9b;
}

.articles__item--sport-special {
  flex-direction: column;
  max-height: none;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .articles__item--sport-special {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .articles__item--sport-special {
    height: 100%;
  }
}

.articles__item--sport-results {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .articles__item--sport-results {
    width: 100%;
    height: auto;
    max-height: 650px;
  }
}

@media (min-width: 768px) {
  .articles__item--sport-results {
    height: 100%;
    max-height: none;
  }
}

@media (max-width: 767px) {
  .articles__item--pr-desktop {
    display: none;
  }
}

@media (max-width: 575px) {
  .articles__item--pr-mobile {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .articles__item--pr-mobile {
    display: none;
  }
}

.articles__item--idvert-mobile {
  width: 100%;
}

@media (min-width: 768px) {
  .articles__item--idvert-mobile {
    display: none !important;
  }
}

.articles--celebrity .articles__item--pr-mobile {
  order: 2;
}

@media (max-width: 575px) {
  .articles--wide .articles__item--aha {
    width: 100%;
    height: auto;
  }

  .articles--wide .articles__item--aha .article-preview__top {
    flex-shrink: 0;
    width: 50%;
    top: 0;
  }

  .articles--wide .articles__item--aha .article-preview__sticker {
    top: initial;
    position: absolute;
    bottom: 0;
  }

  .articles--wide .articles__item--aha .article-preview__title {
    width: calc(50% - 10px);
    height: auto;
    font-size: 4.75vw;
    line-height: 5.5vw;
    position: absolute;
    top: 0;
    right: 0;
  }

  .articles--wide .articles__item:nth-child(3n) {
    height: auto;
  }
}

.article-preview {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.article-preview--vertical-promobox {
  background-color: #000;
}

@media (max-width: 767px) {
  .article-preview--special {
    flex-direction: row;
  }

  .article-preview--big-mobile {
    background-color: #000;
  }
}

.article-preview--horizontal {
  flex-direction: row;
}

.article-preview__premium-sticker {
  z-index: 1;
  background: url("https://img2.cncenter.cz/images/default/svg/premium-sticker.svg?v=2") 0 0 / contain no-repeat;
  width: 70px;
  height: 15px;
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.article-preview__top {
  position: relative;
}

@media (max-width: 767px) {
  .article-preview--special .article-preview__top {
    flex-shrink: 0;
    width: 50%;
  }
}

.article-preview--horizontal .article-preview__top {
  flex-shrink: 0;
  width: 50%;
}

.article-preview__image {
  width: 100%;
  height: auto;
  display: block;
}

.article-preview__play {
  opacity: .8;
  background-color: #d5122c;
  border-radius: 18px;
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media (min-width: 960px) {
  .article-preview__play {
    border-radius: 30px;
    width: 60px;
    height: 60px;
  }
}

.article-preview__play:after {
  content: "";
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid rgba(0, 0, 0, 0);
  border-left: 9px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  bottom: 12px;
  left: 15px;
}

@media (min-width: 960px) {
  .article-preview__play:after {
    border-top-width: 9px;
    border-bottom-width: 9px;
    border-left-width: 16px;
    bottom: 21px;
    left: 24px;
  }
}

.article-preview__sticker {
  text-transform: uppercase;
  color: inherit;
  background-color: #fff;
  padding: 3px 10px 0;
  font: 700 13px / 21px tablet_gothic_condensed, sans-serif;
  text-decoration: none;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.article-preview__sticker[href]:hover {
  text-decoration: underline;
}

@media (min-width: 960px) {
  .article-preview__sticker {
    padding: 5px 20px auto;
    font-size: 15px;
  }
}

.article-preview--vertical-promobox .article-preview__sticker {
  color: #fff;
  background-color: #000;
}

@media (max-width: 767px) {
  .article-preview--big-mobile .article-preview__sticker {
    color: #fff;
    background-color: #000;
  }
}

.article-preview--special .article-preview__sticker {
  color: red;
  bottom: 0;
}

.articles--lifestyle .article-preview__sticker, .article-preview--pr .article-preview__sticker {
  color: #6d6d6d;
}

.article-preview__title {
  color: #000;
  margin: 0;
  padding: 8px 10px;
  font: 700 17px / 21px tablet_gothic_condensed, sans-serif;
  text-decoration: none;
  position: relative;
}

.article-preview__title:hover {
  color: inherit;
  text-decoration: underline;
}

@media (min-width: 576px) {
  .article-preview__title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 22px;
    line-height: 27px;
  }
}

@media (min-width: 768px) {
  .article-preview__title {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (min-width: 960px) {
  .article-preview__title {
    padding: 15px 20px;
    font-size: 30px;
    line-height: 35px;
  }
}

.article-preview--vertical-promobox .article-preview__title {
  color: #fff;
}

@media (max-width: 575px) {
  .article-preview--vertical-promobox .article-preview__title {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 767px) {
  .article-preview--big-mobile .article-preview__title {
    color: #fff;
    font-size: 20px;
    line-height: 25px;
  }
}

.article-preview--special .article-preview__title {
  color: #000;
}

@media (max-width: 767px) {
  .article-preview--special .article-preview__title {
    width: 50%;
    font-size: 26px;
    line-height: 32px;
  }
}

@media (max-width: 575px) {
  .article-preview--special .article-preview__title {
    padding-bottom: 0;
    font-size: 4.75vw;
    line-height: 5.5vw;
  }
}

.article-preview--horizontal .article-preview__title {
  padding: 0 0 0 10px;
  font-family: arial, sans-serif;
  font-size: 11px;
  line-height: 13px;
}

@media (min-width: 960px) {
  .article-preview--horizontal .article-preview__title {
    font-size: 13px;
    line-height: 15px;
  }
}

.container:has( > .articles__item--daily-feed) {
  position: relative;
}

.container:has( > .articles__item--daily-feed):before {
  content: "";
  background: linear-gradient(rgba(204, 213, 222, 0) 0%, #ccd5de 35px 100%);
  width: 2px;
  height: calc(100% - 73px);
  display: block;
  position: absolute;
  top: 73px;
  left: 14px;
}

.container:has( > .articles__item--daily-feed) .breadcrumbs {
  display: none;
}

.container:has( > .articles__item--daily-feed) .daily-feed__h1 {
  text-align: center;
}

.container:has( > .articles__item--daily-feed) .daily-feed__h2 {
  text-align: center;
}

.container:has( > .articles__item--daily-feed) .daily-feed__h1 {
  margin: 24px 0 0;
  font-family: Barlow Condensed, arial, Helvetica, sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
}

.container:has( > .articles__item--daily-feed) .daily-feed__h2 {
  color: #5c6773;
  margin: 8px 0 36px;
  font-family: Barlow, arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

@media (min-width: 768px) {
  .container:has( > .articles__item--daily-feed) {
    width: 576px;
    padding: 0 10px;
  }
}

.articles__item--daily-feed {
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 10px;
}

.articles__item--daily-feed .article-preview {
  margin-left: 20px;
}

@media (min-width: 768px) {
  .articles__item--daily-feed {
    max-height: initial;
  }
}

.article-item-date {
  white-space: nowrap;
  flex: 100%;
  margin: 12px 0;
  padding-left: 20px;
  font-family: Barlow, arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  position: relative;
}

.article-item-date__updated {
  color: #5c6773;
  font-weight: 400;
}

.article-item-date:before {
  content: "";
  background-color: red;
  border: 2px solid #e9eff3;
  border-radius: 51%;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: 1px;
  left: -2px;
}

.latest-news {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.latest-news__header {
  background-color: #084185;
  padding: 12px 10px 8px;
}

@media (min-width: 768px) {
  .latest-news__header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.latest-news__title {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 15px;
}

.latest-news__body {
  flex: 1;
  overflow: hidden;
}

.latest-news__list {
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6 transparent;
  max-height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}

.latest-news__list::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 20px;
}

.latest-news__list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media (min-width: 576px) {
  .latest-news__list {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .latest-news__list {
    padding: 15px;
  }
}

@media (min-width: 960px) {
  .latest-news__list {
    padding: 20px;
  }
}

.latest-news__item {
  margin-bottom: 10px;
  line-height: 18px;
  display: flex;
}

.latest-news__item:last-child {
  margin-bottom: 0;
}

.latest-news__date {
  margin-right: 15px;
  font-size: 12px;
  font-weight: 700;
}

.latest-news__item-title {
  color: #000;
  font-size: 13px;
  text-decoration: none;
}

.latest-news__item-title:hover {
  text-decoration: underline;
}

.latest-news__footer {
  z-index: 1;
  justify-content: center;
  padding: 15px 0;
  display: flex;
  position: relative;
}

.latest-news__footer:before {
  content: "";
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
  width: calc(100% - 10px);
  height: 50px;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.latest-news__link {
  color: #084185;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  position: relative;
}

.latest-news__link:hover {
  text-decoration: underline;
}

.latest-news__link:after {
  content: "";
  border: 3px solid rgba(0, 0, 0, 0);
  border-left: 4px solid;
  border-right: 0 solid;
  width: 0;
  height: 0;
  position: absolute;
  top: 5px;
  left: calc(100% + 5px);
}

.teams-list {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 10px;
  position: relative;
}

@media (max-width: 959px) {
  .teams-list:before {
    content: "";
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to left, #fff 10px, rgba(255, 255, 255, 0));
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.teams-list__inner {
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6 transparent;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.teams-list__inner::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 20px;
}

.teams-list__inner::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media (max-width: 959px) {
  .teams-list__inner {
    overflow-x: auto;
  }
}

@media (min-width: 960px) {
  .teams-list__inner {
    justify-content: space-between;
  }
}

.teams-list__item {
  border-radius: 10px;
  flex: 0 0 50px;
  position: relative;
}

.teams-list__item:hover {
  background-color: #f0f0f0;
}

@media (min-width: 960px) {
  .teams-list__item:hover:after {
    content: attr(data-title);
  }
}

.teams-list__item:after {
  z-index: 2;
  white-space: nowrap;
  color: #fff;
  background-color: #04a545;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 14px;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.match-result {
  color: #000;
  flex-direction: column;
  font-size: 11px;
  font-weight: 700;
  display: flex;
  position: relative;
}

.match-result__link-wrapper {
  color: inherit;
  text-decoration: none;
  display: flex;
}

.match-result--team .match-result__link-wrapper {
  flex-direction: column;
}

.match-result--tennis .match-result__link-wrapper {
  align-items: center;
}

.match-result__top {
  align-self: center;
  margin-bottom: 3px;
}

.match-result--tennis .match-result__time {
  color: #828282;
  width: 60%;
  font-size: 14px;
}

.match-result__state {
  text-transform: uppercase;
  color: red;
}

.match-result__teams-info {
  align-items: center;
  display: flex;
}

.match-result__teams-info--tennis {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.match-result__team {
  flex: 1;
  align-items: center;
  min-height: 24px;
  display: flex;
}

.match-result__teams-info--tennis .match-result__team:first-child {
  margin-bottom: 5px;
}

.match-result__team--first {
  justify-content: flex-end;
}

.match-result__teams-info--tennis .match-result__team {
  width: 100%;
}

.match-result__team-logo {
  flex-shrink: 0;
  width: 24px;
}

.match-result__team-name {
  text-overflow: ellipsis;
  font-size: 12px;
}

.match-result__teams-info--team .match-result__team-name {
  overflow: hidden;
}

.match-result__teams-info--tennis .match-result__team-name {
  width: calc(50% - 24px);
}

@media (min-width: 768px) and (max-width: 959px) {
  .match-result__teams-info--tennis .match-result__team-name {
    width: calc(60% - 24px);
  }
}

.match-result__score {
  text-align: center;
  width: 45px;
  font-size: 20px;
  position: relative;
}

.match-result__score:empty:before {
  content: "";
  background-color: #94939a;
  width: 8px;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) and (max-width: 959px) {
  .match-result__score {
    width: 35px;
    font-size: 16px;
  }
}

.match-result__team-main-score {
  text-align: center;
  width: 20px;
  margin-right: 10px;
}

.match-result__team-main-score--red {
  color: red;
}

@media (min-width: 768px) and (max-width: 959px) {
  .match-result__team-main-score {
    margin-right: 5px;
  }
}

.match-result__team-detail-scores {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.match-result__team-detail-score {
  text-align: center;
  width: 25px;
  font-weight: 400;
  position: relative;
}

.match-result--live .match-result__team-detail-score:last-child {
  color: #ff6000;
}

@media (min-width: 768px) and (max-width: 959px) {
  .match-result__team-detail-score {
    width: 20px;
  }
}

.match-result__team-detail-score sup {
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translateX(calc(7px - 50%));
}

.match-result__bet-icon {
  align-self: center;
  margin-top: 5px;
}

.sport-results {
  background-color: #fff;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
}

@media (min-width: 768px) {
  .sport-results {
    margin-left: auto;
  }
}

.sport-results__header {
  background-color: #04a545;
  padding: 12px 10px 8px;
}

@media (min-width: 960px) {
  .sport-results__header {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sport-results__title {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 15px;
}

@media (min-width: 576px) {
  .sport-results__title {
    font-size: 16px;
  }
}

.sport-results__footer {
  z-index: 1;
  text-align: center;
  justify-content: center;
  padding: 15px 0;
  display: flex;
  position: relative;
}

.sport-results__footer .sport-results__link {
  color: #04a545;
  font: 700 13px arial, sans-serif;
  text-decoration: none;
  position: relative;
}

.sport-results__footer .sport-results__link:hover {
  text-decoration: underline;
}

.sport-results__footer .sport-results__link:after {
  content: "";
  border: 3px solid rgba(0, 0, 0, 0);
  border-left: 4px solid;
  border-right: 0 solid;
  width: 0;
  height: 0;
  position: absolute;
  top: 5px;
  left: calc(100% + 5px);
}

.sport-results__body {
  flex: 1;
  padding: 20px 10px 0;
  position: relative;
  overflow: hidden;
}

.sport-results__body:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .sport-results__body {
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 960px) {
  .sport-results__body {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.sport-results__body .sport-results__sports, .sport-results__body .sport-results__leagues, .sport-results__body .sport-results__matches {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sport-results__body .sport-results__sport {
  margin-bottom: 20px;
}

.sport-results__body .sport-results__sport:last-child {
  margin-bottom: 0;
  margin-right: 0;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__sport {
    margin-bottom: 15px;
  }
}

.sport-results__body .sport-results__sport-title {
  text-transform: uppercase;
  color: #d21e1e;
  align-items: center;
  margin: 0 0 5px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 15px;
  display: flex;
}

.sport-reslts__sport--football .sport-results__body .sport-results__sport-title {
  color: #00a442;
}

.sport-results__sport--hockey .sport-results__body .sport-results__sport-title {
  color: #325edc;
}

.sport-results__sport--tennis .sport-results__body .sport-results__sport-title {
  color: #ff6000;
}

@media (min-width: 768px) {
  .sport-results__body .sport-results__sport-title {
    padding-left: 5px;
  }
}

@media (min-width: 960px) {
  .sport-results__body .sport-results__sport-title {
    padding-left: 10px;
  }
}

.sport-results__body .sport-results__sport-icon {
  width: 16px;
  height: 16px;
  margin: -5px 0 0 10px;
}

.sport-results__body .sport-results__leagues {
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  padding: 15px 10px 20px;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__leagues {
    padding: 10px 5px;
  }
}

.sport-results__body .sport-results__league {
  margin-bottom: 20px;
}

.sport-results__body .sport-results__league:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__league {
    margin-bottom: 15px;
  }
}

.sport-results__body .sport-results__league-title {
  text-align: center;
  text-transform: uppercase;
  color: #d21e1e;
  margin: 0 0 10px;
  font-size: 12px;
}

.sport-reslts__sport--football .sport-results__body .sport-results__league-title {
  color: #00a442;
}

.sport-results__sport--hockey .sport-results__body .sport-results__league-title {
  color: #325edc;
}

.sport-results__sport--tennis .sport-results__body .sport-results__league-title {
  color: #ff6000;
}

.sport-results__body .sport-results__league-title a {
  color: inherit;
  text-decoration: none;
}

.sport-results__body .sport-results__league-title a:hover {
  text-decoration: underline;
}

.sport-results__body .sport-results__match {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

.sport-results__body .sport-results__match:last-child {
  margin-bottom: 0;
}

.sport-results__league:last-child .sport-results__body .sport-results__match:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .sport-results__body .sport-results__match {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__match {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
}

.sport-results__body .sport-results__event {
  color: #1e1e1e;
  height: 100%;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-decoration: none;
  display: flex;
}

.sport-special__match:first-child .sport-results__body .sport-results__event {
  border-top: 1px solid #dcdcdc;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__event {
    font-size: 11px;
    line-height: 13px;
  }
}

.sport-results__body .sport-results__event-info {
  border-right: 1px solid #e0e0e0;
  flex-direction: column;
  align-items: center;
  width: 50px;
  padding: 10px;
  line-height: 15px;
  display: flex;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__event-info {
    width: 40px;
    padding: 5px;
  }
}

.sport-results__body .sport-results__event-title {
  margin-right: auto;
  padding: 10px;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__event-title {
    padding: 5px;
  }
}

.sport-results__body .sport-results__event-additional-info {
  flex-shrink: 0;
  padding: 10px;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-results__body .sport-results__event-additional-info {
    padding: 5px;
  }
}

.sport-results__body .sport-results__event-medal {
  margin-left: auto;
}

.sport-results__body .sport-results__flag {
  width: 16px;
  height: 11px;
}

.sport-results__body .sport-results__footer {
  z-index: 1;
  justify-content: center;
  padding: 15px 0;
  display: flex;
  position: relative;
}

.sport-results__body .sport-results__link {
  color: #04a545;
  font: 700 13px arial, sans-serif;
  text-decoration: none;
  position: relative;
}

.sport-results__body .sport-results__link:hover {
  text-decoration: underline;
}

.sport-results__body .sport-results__link:after {
  content: "";
  border: 3px solid rgba(0, 0, 0, 0);
  border-left: 4px solid;
  border-right: 0 solid;
  width: 0;
  height: 0;
  position: absolute;
  top: 5px;
  left: calc(100% + 5px);
}

.sport-results__body .sport-results__noresults {
  color: #fff;
  background-color: #04a545;
  width: 100%;
  height: 100%;
  display: block;
}

.sport-results__body .sport-results__noresults-content {
  text-align: center;
  height: 100%;
  padding: 10px;
  font-size: 12px;
  line-height: 22px;
  display: block;
}

.sport-results__body .sport-results__noresults-text {
  width: 100%;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.sport-results__body .sport-results__noresults-text a {
  color: #fff;
}

.sport-results__body .sport-results__noresults-logo {
  background-image: url("https://img2.cncenter.cz/images/isportcz/dist/svg/logo-isport-white.svg?v=2");
  background-repeat: no-repeat;
  width: 90px;
  height: 30px;
  margin: 20px auto 0;
}

.sport-special {
  flex-direction: column;
  height: 100%;
  display: flex;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .12);
}

.sport-special-wrapper {
  background-color: #fff;
}

.sport-special__header {
  color: #fff;
  background: #325edc center / cover no-repeat;
  padding: 10px;
  display: flex;
}

.sport-special__header--euro {
  background: #02849a url("/images/blesk/svg/euro-special-background.svg");
}

.sport-special__header--olympic-games {
  color: #94754d;
  box-sizing: content-box;
  background: url("/images/blesk/standalone/zoh/olympic-logo.png") right 20px top 15px / 63px 29px no-repeat;
  padding: 21px;
  display: block;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__header--olympic-games {
    font-size: 20px;
    line-height: 25px;
  }
}

.sport-special__title {
  margin: 0 0 5px;
  font-size: 18px;
  line-height: 22px;
}

.sport-special__header--olympic-games .sport-special__title {
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__header--olympic-games .sport-special__title {
    margin-bottom: 2px;
    padding-bottom: 16px;
    font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 25px;
  }
}

.sport-special__title a {
  color: inherit;
  text-decoration: none;
}

.sport-special__title a:hover {
  text-decoration: underline;
}

.sport-special__subtitle {
  margin: 0;
  font-size: 12px;
  line-height: 14px;
}

.sport-special__menu {
  border-left: 1px solid #4ea8f6;
  margin: 0 0 0 auto;
  padding: 0 0 0 10px;
  list-style: none;
}

.sport-special__header--euro .sport-special__menu {
  border-left-color: #015d6b;
}

.sport-special__header--olympic-games .sport-special__menu {
  background: #0e64b9 url("/images/blesk/standalone/zoh/zoh-2022-bg-header.png") 100% no-repeat;
  border-left: 0;
  margin-top: 16px;
  margin-left: -21px;
  margin-right: -21px;
  padding: 0 12px;
  display: flex;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__header--olympic-games .sport-special__menu {
    margin-top: 0;
  }
}

.sport-special__menu-item {
  margin-bottom: 3px;
}

.sport-special__menu-item:last-child {
  margin-bottom: 0;
}

.sport-special__header--olympic-games .sport-special__menu-item {
  text-align: center;
  margin: 0;
  padding: 0 7.5px;
  line-height: 25px;
  display: inline-block;
}

@media (min-width: 375px) and (max-width: 424px) {
  .sport-special__header--olympic-games .sport-special__menu-item {
    min-width: 76px;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .sport-special__header--olympic-games .sport-special__menu-item {
    min-width: 94px;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__header--olympic-games .sport-special__menu-item:last-child {
    display: none;
  }
}

.sport-special__header--olympic-games .sport-special__menu-item:first-child:before {
  content: "";
  background-image: url("/images/blesk/standalone/zoh/olympic-live.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  width: 8px;
  height: 8px;
  margin-top: 17px;
  margin-left: -22px;
  position: absolute;
}

.sport-special__menu-link {
  text-transform: uppercase;
  color: inherit;
  width: 100%;
  padding-right: 10px;
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
  text-decoration: none;
  display: block;
  position: relative;
}

.sport-special__menu-link:hover {
  text-decoration: underline;
}

.sport-special__menu-link:after {
  content: "";
  border-top: 2px solid #fff;
  border-bottom: none;
  border-left: none;
  border-right: 2px solid #fff;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)rotate(45deg);
}

.sport-special__header--olympic-games .sport-special__menu-link {
  color: #fff;
  padding: 17px 0;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 25px;
}

.sport-special__header--olympic-games .sport-special__menu-link:after {
  content: "";
  border-width: 0;
  width: 0;
  height: 0;
}

.sport-special__inner-title {
  text-transform: uppercase;
  color: #94754d;
  margin: 0;
  padding: 20px 0 10px 15px;
  font-size: 13px;
  line-height: 15px;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__inner-title {
    padding: 8px initial 5px 10px;
    font-size: 12px;
    line-height: 12px;
  }
}

.sport-special__matches {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sport-special__matches--olympic-games {
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6 transparent;
  overflow: auto;
}

.sport-special__matches--olympic-games::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 20px;
}

.sport-special__matches--olympic-games::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media (min-width: 768px) {
  .sport-special__matches--olympic-games {
    height: 250px;
  }
}

@media (min-width: 960px) {
  .sport-special__matches--olympic-games {
    height: 300px;
  }
}

.sport-special__match {
  border-bottom: 1px solid #dcdcdc;
}

.sport-special__match:nth-child(2n) {
  background-color: #f0f0f0;
}

.sport-special__match:hover {
  background-color: #ddd;
}

.sport-special__match-link {
  color: inherit;
  padding: 9px 0;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__match-link {
    padding-top: 4px;
    padding-bottom: 3px;
  }
}

.sport-special__phase {
  text-align: center;
  color: #94939a;
  margin-bottom: 5px;
  font-size: 11px;
  font-weight: normal;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__phase {
    margin-bottom: 2px;
    font-size: 10px;
  }
}

.sport-special__datetime {
  text-align: center;
  color: #000;
  margin-bottom: 2px;
  font-size: 11px;
  position: relative;
}

.sport-special__state-flag {
  text-transform: uppercase;
  color: red;
}

.sport-special__match-info {
  align-items: center;
  display: flex;
}

.sport-special__team-info {
  flex: 1;
  align-items: center;
  display: flex;
}

.sport-special__team-info--first {
  justify-content: flex-end;
}

.sport-special__team-name {
  padding: 0 5px;
  font-size: 12px;
}

.sport-special__team-info--first .sport-special__team-name {
  text-align: right;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__team-name {
    font-size: 11px;
  }
}

.sport-special__score {
  text-align: center;
  color: #000;
  width: 45px;
  font-size: 20px;
  position: relative;
}

.sport-special__score:empty:before {
  content: "";
  background-color: #94939a;
  width: 8px;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__score {
    width: 40px;
    font-size: 16px;
  }
}

.sport-special__articles {
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sport-special__articles--olympic-games {
  padding: 0 20px;
  position: relative;
}

@media (max-width: 767px) {
  .sport-special__articles {
    display: none;
  }

  .sport-special__articles--olympic-games {
    display: block;
  }
}

.sport-special__article {
  border-bottom: 1px solid #dcdcdc;
  padding: 12px 0;
}

.sport-special__article--olympic-games {
  border: 0;
  padding: 0 0 12px;
  position: relative;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__article--olympic-games:nth-child(3) {
    display: none;
  }

  .sport-special__article--olympic-games:nth-child(2) {
    border-bottom: 1px solid #e4e4e4;
  }
}

.sport-special__article--olympic-games:last-child {
  border-bottom: 1px solid #e4e4e4;
}

@media (min-width: 768px) and (max-width: 959px) {
  .sport-special__article {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.sport-special__link {
  text-align: center;
  color: #325edc;
  margin: auto;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 700;
}

.sport-special__link--euro {
  color: #02849a;
}

.sport-special__link--olympic-games {
  color: #0e64b9;
  width: 100%;
  padding: 0 21px 21px;
  text-decoration: none;
}

.sport-special__link--olympic-games:after {
  content: "";
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #0e64b9;
  width: 0;
  height: 0;
  margin-top: 8px;
  margin-left: 6px;
  position: absolute;
  transform: translateY(-50%)rotate(0);
}

@media min-laptop and (max-width: 959px) {
  .sport-special__link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.box-oh-medaile {
  box-sizing: content-box;
  border-top: 1px solid #dcdcdc;
  height: 48px;
  margin: 0 20px 20px;
  padding-top: 20px;
}

@media (min-width: 768px) and (max-width: 959px) {
  .box-oh-medaile {
    margin: 0 20px 15px;
  }
}

.box-oh-medaile__vlajka {
  float: left;
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/vlajka@2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 110px 69px;
  width: 79px;
  height: 48px;
}

.box-oh-medaile__container {
  float: left;
  width: calc(100% - 79px);
}

.box-oh-medaile__cz {
  text-align: left;
  float: left;
  width: 58px;
  margin-left: 8px;
  margin-right: 21px;
  padding-top: 7px;
  font-size: 13px;
  font-weight: bold;
  line-height: 17px;
}

.box-oh-medaile__cz span {
  display: none;
}

@media (min-width: 768px) and (max-width: 959px) {
  .box-oh-medaile__cz {
    display: none;
  }
}

.box-oh-medaile__medaile {
  text-align: center;
  float: right;
}

.box-oh-medaile__medaile span {
  color: #fff;
  background-repeat: no-repeat;
  background-size: 22px 34px;
  width: 22px;
  margin: 8px 3px 0;
  padding: 15px 0 4px;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
}

.box-oh-medaile__gold {
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/medal-gold.svg");
}

.box-oh-medaile__silver {
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/medal-silver.svg");
}

.box-oh-medaile__bronze {
  background-image: url("//img2.cncenter.cz/images/isportcz/standalone/zoh/medal-bronze.svg");
}

.box-oh-medaile:after {
  clear: both;
}

.olympic-games-promo-events {
  width: calc(100% - 10px);
  margin-bottom: 0 !important;
  padding: 20px !important;
}

@media (min-width: 768px) and (max-width: 959px) {
  .olympic-games-promo-events {
    padding: 10px 20px !important;
  }
}

@media (max-width: 320px) {
  .olympic-games-promo-events {
    box-sizing: border-box;
    max-width: 258px;
    margin-left: 20px;
    padding: 20px 0 !important;
  }
}

@media (min-width: 321px) and (max-width: 425px) {
  .olympic-games-promo-events {
    max-width: 350px;
  }
}

.olympic-games-promo-events__link {
  max-height: 37px;
  overflow: hidden;
}

@media (min-width: 768px) and (max-width: 959px) {
  .olympic-games-promo-events__link:nth-child(3), .olympic-games-promo-events__link:nth-child(4) {
    display: none;
  }
}

.sport-special__article--olympic-games .article-preview__top {
  width: 111px;
}

.special--red {
  background: #c00;
}

.special--red .special-header__title {
  color: #fff;
}

.special--red .special-header__button {
  background-color: #000;
}

.special--red .special-unimenu__link {
  color: #fff;
  background-color: #000;
}

.special--red .special-header__button, .special--red .special-unimenu__link, .special--red .special__button {
  transition: all .3s ease-in-out;
}

.special--red .special-header__button:hover, .special--red .special-header__button:active, .special--red .special-header__button:focus, .special--red .special-unimenu__link:hover, .special--red .special-unimenu__link:active, .special--red .special-unimenu__link:focus, .special--red .special__button:hover, .special--red .special__button:active, .special--red .special__button:focus {
  background-color: #3d3d3d;
}

.special--red .special__button {
  background-color: #000;
}

.special--red a {
  text-decoration: none;
}

.special--blue_dark {
  background: #09092b;
}

.special--blue_dark .special-header__title {
  color: #fff;
}

.special--blue_dark .special-header__button {
  background-color: red;
}

.special--blue_dark .special-unimenu__link {
  color: #fff;
  background-color: red;
}

.special--blue_dark .special-header__button, .special--blue_dark .special-unimenu__link, .special--blue_dark .special__button {
  transition: all .3s ease-in-out;
}

.special--blue_dark .special-header__button:hover, .special--blue_dark .special-header__button:active, .special--blue_dark .special-header__button:focus, .special--blue_dark .special-unimenu__link:hover, .special--blue_dark .special-unimenu__link:active, .special--blue_dark .special-unimenu__link:focus, .special--blue_dark .special__button:hover, .special--blue_dark .special__button:active, .special--blue_dark .special__button:focus {
  background-color: #c90000;
}

.special--blue_dark .special__button {
  background-color: red;
}

.special--blue_dark a {
  text-decoration: none;
}

.special--blue_news {
  background: #084185;
}

.special--blue_news .special-header__title {
  color: #fff;
}

.special--blue_news .special-header__button {
  background-color: red;
}

.special--blue_news .special-unimenu__link {
  color: #fff;
  background-color: red;
}

.special--blue_news .special-header__button, .special--blue_news .special-unimenu__link, .special--blue_news .special__button {
  transition: all .3s ease-in-out;
}

.special--blue_news .special-header__button:hover, .special--blue_news .special-header__button:active, .special--blue_news .special-header__button:focus, .special--blue_news .special-unimenu__link:hover, .special--blue_news .special-unimenu__link:active, .special--blue_news .special-unimenu__link:focus, .special--blue_news .special__button:hover, .special--blue_news .special__button:active, .special--blue_news .special__button:focus {
  background-color: #c90000;
}

.special--blue_news .special__button {
  background-color: red;
}

.special--blue_news a {
  text-decoration: none;
}

.special--blue_light {
  background: #b4d3f8;
}

.special--blue_light .special-header__title {
  color: #03254e;
}

.special--blue_light .special-header__button {
  background-color: red;
}

.special--blue_light .special-unimenu__link {
  color: #fff;
  background-color: red;
}

.special--blue_light .special-header__button, .special--blue_light .special-unimenu__link, .special--blue_light .special__button {
  transition: all .3s ease-in-out;
}

.special--blue_light .special-header__button:hover, .special--blue_light .special-header__button:active, .special--blue_light .special-header__button:focus, .special--blue_light .special-unimenu__link:hover, .special--blue_light .special-unimenu__link:active, .special--blue_light .special-unimenu__link:focus, .special--blue_light .special__button:hover, .special--blue_light .special__button:active, .special--blue_light .special__button:focus {
  background-color: #c90000;
}

.special--blue_light .special__button {
  background-color: red;
}

.special--blue_light a {
  text-decoration: none;
}

.special--green {
  background: #2c8845;
}

.special--green .special-header__title {
  color: #fff;
}

.special--green .special-header__button {
  background-color: #000;
}

.special--green .special-unimenu__link {
  color: #fff;
  background-color: #000;
}

.special--green .special-header__button, .special--green .special-unimenu__link, .special--green .special__button {
  transition: all .3s ease-in-out;
}

.special--green .special-header__button:hover, .special--green .special-header__button:active, .special--green .special-header__button:focus, .special--green .special-unimenu__link:hover, .special--green .special-unimenu__link:active, .special--green .special-unimenu__link:focus, .special--green .special__button:hover, .special--green .special__button:active, .special--green .special__button:focus {
  background-color: #3d3d3d;
}

.special--green .special__button {
  background-color: #000;
}

.special--green a {
  text-decoration: none;
}

.special--black {
  background: #131313;
}

.special--black .special-header__title {
  color: #fff;
}

.special--black .special-header__button {
  background-color: red;
}

.special--black .special-unimenu__link {
  color: #fff;
  background-color: red;
}

.special--black .special-header__button, .special--black .special-unimenu__link, .special--black .special__button {
  transition: all .3s ease-in-out;
}

.special--black .special-header__button:hover, .special--black .special-header__button:active, .special--black .special-header__button:focus, .special--black .special-unimenu__link:hover, .special--black .special-unimenu__link:active, .special--black .special-unimenu__link:focus, .special--black .special__button:hover, .special--black .special__button:active, .special--black .special__button:focus {
  background-color: #c90000;
}

.special--black .special__button {
  background-color: red;
}

.special--black a {
  text-decoration: none;
}

.special--glamour {
  background: #181c27;
}

.special--glamour .special-header__title {
  color: #fff;
}

.special--glamour .special-header__button {
  background-color: red;
}

.special--glamour .special-unimenu__link {
  color: #fff;
  background-color: red;
}

.special--glamour .special-header__button, .special--glamour .special-unimenu__link, .special--glamour .special__button {
  transition: all .3s ease-in-out;
}

.special--glamour .special-header__button:hover, .special--glamour .special-header__button:active, .special--glamour .special-header__button:focus, .special--glamour .special-unimenu__link:hover, .special--glamour .special-unimenu__link:active, .special--glamour .special-unimenu__link:focus, .special--glamour .special__button:hover, .special--glamour .special__button:active, .special--glamour .special__button:focus {
  background-color: #c90000;
}

.special--glamour .special__button {
  background-color: red;
}

.special--glamour a {
  text-decoration: none;
}

.special--hockey {
  background: #b4d3f8;
}

.special--hockey .special-header__title {
  color: #03254e;
}

.special--hockey .special-header__button {
  background-color: red;
}

.special--hockey .special-unimenu__link {
  color: #fff;
  background-color: red;
}

.special--hockey .special-header__button, .special--hockey .special-unimenu__link, .special--hockey .special__button {
  transition: all .3s ease-in-out;
}

.special--hockey .special-header__button:hover, .special--hockey .special-header__button:active, .special--hockey .special-header__button:focus, .special--hockey .special-unimenu__link:hover, .special--hockey .special-unimenu__link:active, .special--hockey .special-unimenu__link:focus, .special--hockey .special__button:hover, .special--hockey .special__button:active, .special--hockey .special__button:focus {
  background-color: #c90000;
}

.special--hockey .special__button {
  background-color: red;
}

.special--hockey a {
  text-decoration: none;
}

.special--gradient_wine {
  background: linear-gradient(#771b2a, #a33246 46%, #d54c66);
}

.special--gradient_wine .special-header__title {
  color: #fff;
}

.special--gradient_wine .special-header__button {
  background-color: #cb506f;
}

.special--gradient_wine .special-unimenu__link {
  color: #771b29;
  background-color: #fff;
}

.special--gradient_wine .special-header__button, .special--gradient_wine .special-unimenu__link, .special--gradient_wine .special__button {
  transition: all .3s ease-in-out;
}

.special--gradient_wine .special-header__button:hover, .special--gradient_wine .special-header__button:active, .special--gradient_wine .special-header__button:focus, .special--gradient_wine .special-unimenu__link:hover, .special--gradient_wine .special-unimenu__link:active, .special--gradient_wine .special-unimenu__link:focus, .special--gradient_wine .special__button:hover, .special--gradient_wine .special__button:active, .special--gradient_wine .special__button:focus {
  color: #fff;
  background-color: #ff1a54;
}

.special--gradient_wine .special__button {
  background-color: #c4884e;
}

.special--gradient_wine .special__button:hover, .special--gradient_wine .special__button:active, .special--gradient_wine .special__button:focus {
  background-color: #9d6b3a;
}

.special--gradient_wine a {
  text-decoration: none;
}

.special--zoh {
  background: #afe7f9;
}

.special--zoh .special-header__title {
  color: #000;
}

.special--zoh .special-header__button {
  background-color: #262a36;
}

.special--zoh .special-unimenu__link {
  color: #fff;
  background-color: #262a36;
}

.special--zoh .special-header__button, .special--zoh .special-unimenu__link, .special--zoh .special__button {
  transition: all .3s ease-in-out;
}

.special--zoh .special-header__button:hover, .special--zoh .special-header__button:active, .special--zoh .special-header__button:focus, .special--zoh .special-unimenu__link:hover, .special--zoh .special-unimenu__link:active, .special--zoh .special-unimenu__link:focus, .special--zoh .special__button:hover, .special--zoh .special__button:active, .special--zoh .special__button:focus {
  background-color: #d1eef7;
}

.special--zoh .special__button {
  background-color: #262a36;
}

.special--zoh a {
  text-decoration: none;
}

.special--loh_2024 {
  background: #2293cd;
}

.special--loh_2024 .special-header__title {
  color: #0b5d84;
}

.special--loh_2024 .special-header__button {
  background-color: #fff;
}

.special--loh_2024 .special-unimenu__link {
  color: #fff;
  background-color: #0b5d84;
}

.special--loh_2024 .special-header__button, .special--loh_2024 .special-unimenu__link, .special--loh_2024 .special__button {
  transition: all .3s ease-in-out;
}

.special--loh_2024 .special-header__button:hover, .special--loh_2024 .special-header__button:active, .special--loh_2024 .special-header__button:focus, .special--loh_2024 .special-unimenu__link:hover, .special--loh_2024 .special-unimenu__link:active, .special--loh_2024 .special-unimenu__link:focus, .special--loh_2024 .special__button:hover, .special--loh_2024 .special__button:active, .special--loh_2024 .special__button:focus {
  background-color: #003561;
}

.special--loh_2024 .special__button {
  background-color: #fff;
}

.special--loh_2024 a {
  text-decoration: none;
}

.special--ms_fotbal {
  background: #8a1538;
}

.special--ms_fotbal .special-header__title {
  color: #f6fdfb;
}

.special--ms_fotbal .special-header__button {
  background-color: #00ceb7;
}

.special--ms_fotbal .special-unimenu__link {
  color: #f6fdfb;
  background-color: #8a1538;
}

.special--ms_fotbal .special-header__button, .special--ms_fotbal .special-unimenu__link, .special--ms_fotbal .special__button {
  transition: all .3s ease-in-out;
}

.special--ms_fotbal .special-header__button:hover, .special--ms_fotbal .special-header__button:active, .special--ms_fotbal .special-header__button:focus, .special--ms_fotbal .special-unimenu__link:hover, .special--ms_fotbal .special-unimenu__link:active, .special--ms_fotbal .special-unimenu__link:focus, .special--ms_fotbal .special__button:hover, .special--ms_fotbal .special__button:active, .special--ms_fotbal .special__button:focus, .special--ms_fotbal .special__button {
  background-color: #00ceb7;
}

.special--ms_fotbal a {
  text-decoration: none;
}

.special--ms_fotbal .special-unimenu .special-unimenu__item a:hover {
  color: #8a1538;
  background-color: #fff;
}

.special--ms_hokej_22 {
  background: #0b2a61;
}

.special--ms_hokej_22 .special-header__title {
  color: #f6fdfb;
}

.special--ms_hokej_22 .special-header__button {
  background-color: #10fdc2;
}

.special--ms_hokej_22 .special-unimenu__link {
  color: #f6fdfb;
  background-color: #0b2a61;
}

.special--ms_hokej_22 .special-header__button, .special--ms_hokej_22 .special-unimenu__link, .special--ms_hokej_22 .special__button {
  transition: all .3s ease-in-out;
}

.special--ms_hokej_22 .special-header__button:hover, .special--ms_hokej_22 .special-header__button:active, .special--ms_hokej_22 .special-header__button:focus, .special--ms_hokej_22 .special-unimenu__link:hover, .special--ms_hokej_22 .special-unimenu__link:active, .special--ms_hokej_22 .special-unimenu__link:focus, .special--ms_hokej_22 .special__button:hover, .special--ms_hokej_22 .special__button:active, .special--ms_hokej_22 .special__button:focus {
  color: #0b2a61;
  background-color: #10fdc2;
}

.special--ms_hokej_22 .special__button {
  background-color: #10fdc2;
}

.special--ms_hokej_22 a {
  text-decoration: none;
}

.special--ms_hokej_23 {
  background: #1567a1;
}

.special--ms_hokej_23 .special-header__title {
  color: #f6fdfb;
}

.special--ms_hokej_23 .special-header__button {
  background-color: #fff;
}

.special--ms_hokej_23 .special-unimenu__link {
  color: #f6fdfb;
  background-color: #1593cf;
}

.special--ms_hokej_23 .special-header__button, .special--ms_hokej_23 .special-unimenu__link, .special--ms_hokej_23 .special__button {
  transition: all .3s ease-in-out;
}

.special--ms_hokej_23 .special-header__button:hover, .special--ms_hokej_23 .special-header__button:active, .special--ms_hokej_23 .special-header__button:focus, .special--ms_hokej_23 .special-unimenu__link:hover, .special--ms_hokej_23 .special-unimenu__link:active, .special--ms_hokej_23 .special-unimenu__link:focus, .special--ms_hokej_23 .special__button:hover, .special--ms_hokej_23 .special__button:active, .special--ms_hokej_23 .special__button:focus {
  background-color: #1567a1;
}

.special--ms_hokej_23 .special__button {
  background-color: #fff;
}

.special--ms_hokej_23 a {
  text-decoration: none;
}

.special--ms_hokej_22 .special-header__button, .special--ms_hokej_22 .special__button {
  color: #0b2a61;
  background-color: #10fdc2;
}

.special--ms_hokej_22 .special-header__button:after, .special--ms_hokej_22 .special__button:after {
  border-left-color: #0b2a61;
}

.special--ms_hokej_23 .special-header__button, .special--ms_hokej_23 .special__button {
  color: #1567a1;
  background-color: #fff;
}

.special--ms_hokej_23 .special-header__button:hover, .special--ms_hokej_23 .special__button:hover {
  color: #1567a1;
  background-color: #ededee;
}

.special--ms_hokej_23 .special-header__button:after, .special--ms_hokej_23 .special__button:after {
  border-left-color: #1567a1;
}

.special--ms_fotbal .special-header__button, .special--ms_fotbal .special__button {
  color: #8a1538;
}

.special--ms_fotbal .special-header__button:after, .special--ms_fotbal .special__button:after {
  border-left-color: #8a1538;
}

.special--loh_2024 .special-header__button, .special--loh_2024 .special__button {
  color: #0b2a61;
}

.special--loh_2024 .special-header__button:hover, .special--loh_2024 .special__button:hover {
  color: #fff;
}

.special--loh_2024 .special-header__button:hover:after, .special--loh_2024 .special__button:hover:after {
  border-left-color: #fff;
}

.special--loh_2024 .special-header__button:after, .special--loh_2024 .special__button:after {
  border-left-color: #0b2a61;
}

@media (max-width: 575px) {
  .special--ms_hokej_23 .special-header__button, .special--ms_hokej_23 .special__button {
    color: #1567a1;
    background-color: #fff;
  }

  .special--ms_hokej_23 .special-header__button:hover, .special--ms_hokej_23 .special__button:hover {
    color: #1567a1;
    background-color: #ededee;
  }

  .special--ms_hokej_23 .special-header__button:after, .special--ms_hokej_23 .special__button:after {
    border-left-color: #1567a1;
  }

  .special--loh_2024 .special-header__button, .special--loh_2024 .special__button {
    color: #0b2a61;
  }

  .special--loh_2024 .special-header__button:hover, .special--loh_2024 .special__button:hover {
    color: #fff;
  }

  .special--loh_2024 .special-header__button:hover:after, .special--loh_2024 .special__button:hover:after {
    border-left-color: #fff;
  }

  .special--loh_2024 .special-header__button:after, .special--loh_2024 .special__button:after {
    border-left-color: #0b2a61;
  }
}

.special--glamour {
  background-image: url("/images/blesk/special-glamour-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.special--hockey {
  background-image: url("/images/blesk/special-hockey-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.special-header {
  margin: 0 0 10px;
}

@media (min-width: 768px) {
  .special-header {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    display: flex;
  }
}

.special-header--with-title {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .special-header--with-title {
    padding-left: 10px;
  }
}

.special-header__title {
  text-transform: uppercase;
  margin: 8px 0 0;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 25px;
}

@media (max-width: 767px) {
  .special-header__title {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .special-header__title {
    font-size: 30px;
  }
}

.special-header__title--link {
  display: block;
}

.special-header__title--link:hover {
  text-decoration: underline;
}

@media (max-width: 575px) {
  .special-header__logo {
    margin: auto;
  }
}

@media (min-width: 576px) {
  .special-header__logo {
    max-width: 320px;
  }
}

@media (min-width: 768px) {
  .special-header__logo {
    max-width: 435px;
  }
}

.special-header__button {
  color: #fff;
  align-items: center;
  margin: 0 0 0 10px;
  padding: 12px 20px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
}

@media (max-width: 767px) {
  .special-header__button {
    display: none;
  }
}

.special-header__button:after {
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: #fff;
  margin: 0 0 0 10px;
}

.special-unimenu {
  flex: 0 0 100%;
  align-items: center;
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  display: flex;
  overflow-x: auto;
}

.special-unimenu__item {
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 10px 0 0;
}

.special-unimenu__item:last-child {
  margin: 0;
}

@media (min-width: 768px) {
  .special-unimenu__item {
    flex: 1;
    margin: 0 20px 0 0;
  }
}

.special-unimenu__link {
  text-align: center;
  text-transform: uppercase;
  padding: 8px 15px;
  font-size: 11px;
  font-weight: bold;
  display: block;
}

@media (min-width: 576px) {
  .special-unimenu__link {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .special-unimenu__link {
    padding: 6px 0;
    font-size: 15px;
  }
}

.main-article {
  margin: 0 0 10px;
}

.main-article__image {
  width: 100%;
  margin: auto;
}

.special-online {
  background-color: #fff;
  align-items: center;
  height: 32px;
  margin: 0 0 10px;
  font-weight: bold;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .special-online {
    height: 42px;
  }
}

.special-online__status {
  background-color: #4a4a4a;
  flex: 0 0 60px;
  align-items: center;
  height: 100%;
  padding: 0 0 0 5px;
  display: flex;
}

@media (min-width: 576px) {
  .special-online__status {
    flex-basis: 70px;
  }
}

@media (min-width: 768px) {
  .special-online__status {
    flex-basis: 95px;
    padding-left: 10px;
  }
}

.special-online__status:before {
  content: "";
}

.special-online--online .special-online__status:before {
  background-color: #1fff0e;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: 0 5px 0 0;
  box-shadow: 0 0 9px #5dff00;
}

@media (min-width: 768px) {
  .special-online--online .special-online__status:before {
    width: 10px;
    height: 10px;
    margin-right: 12px;
  }
}

.special-online--live .special-online__status:before, .special-online--livestream .special-online__status:before, .special-online--waiting .special-online__status:before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 14px;
  height: 9px;
}

@media (min-width: 768px) {
  .special-online--live .special-online__status:before, .special-online--livestream .special-online__status:before, .special-online--waiting .special-online__status:before {
    width: 18px;
    height: 12px;
  }
}

.special-online--live .special-online__status:before, .special-online--livestream .special-online__status:before {
  filter: drop-shadow(0 0 3px #1fff0e);
  background-image: url("/images/blesk/dist/svg/online-broadcast-camera-green.svg");
}

.special-online--live .special-online__status:before {
  margin: 0 7px 0 0;
}

@media (min-width: 576px) {
  .special-online--live .special-online__status:before {
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .special-online--live .special-online__status:before {
    margin-right: 18px;
  }
}

.special-online--livestream .special-online__status:before, .special-online--waiting .special-online__status:before {
  flex-shrink: 0;
}

.special-online--waiting .special-online__status:before {
  filter: drop-shadow(0 0 3px #f80);
  background-image: url("/images/blesk/dist/svg/online-broadcast-camera-orange.svg");
}

.special-online__status-text {
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
}

@media (min-width: 576px) {
  .special-online__status-text {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .special-online__status-text {
    font-size: 13px;
  }
}

.special-online--livestream .special-online__status-text, .special-online--waiting .special-online__status-text {
  text-align: center;
  font-size: 7px;
}

@media (min-width: 576px) {
  .special-online--livestream .special-online__status-text, .special-online--waiting .special-online__status-text {
    font-size: 9px;
  }
}

@media (min-width: 768px) {
  .special-online--livestream .special-online__status-text, .special-online--waiting .special-online__status-text {
    font-size: 11px;
  }
}

.special-online__time {
  color: #ed1d25;
  margin: 0 0 0 5px;
  font-size: 11px;
}

@media (min-width: 576px) {
  .special-online__time {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .special-online__time {
    margin-left: 10px;
    font-size: 13px;
  }
}

.special-online__title {
  color: #000;
  white-space: nowrap;
  margin: 0 0 0 5px;
  font-size: 13px;
  font-weight: normal;
}

@media (min-width: 576px) {
  .special-online__title {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .special-online__title {
    margin-left: 10px;
    font-size: 13px;
    font-weight: bold;
  }
}

@media (min-width: 960px) {
  .special-online__title {
    font-size: 15px;
  }
}

.special-online__title:hover, .special-online__title:active, .special-online__title:focus {
  text-decoration: underline;
}

@media (min-width: 576px) {
  .special-online--online .special-online__title {
    margin-left: 10px;
  }
}

@media (min-width: 960px) {
  .special-online--online .special-online__title {
    margin-left: 12px;
  }
}

.special-online__link {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 55%);
  width: 45px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 768px) {
  .special-online__link {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 20%);
    align-items: center;
    width: auto;
    padding: 0 10px 0 40px;
    display: flex;
  }
}

@media (min-width: 960px) {
  .special-online__link {
    padding-right: 20px;
  }
}

.special-online__link:after {
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: #ed1d25;
}

@media (max-width: 767px) {
  .special-online__link:after {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) {
  .special-online__link:after {
    margin-left: 10px;
  }
}

@media (min-width: 576px) {
  .special-online--live .special-online__link:after, .special-online--livestream .special-online__link:after, .special-online--waiting .special-online__link:after {
    background-repeat: no-repeat;
    background-size: 100%;
    border: none;
    width: 14px;
    height: 14px;
  }
}

@media (min-width: 768px) {
  .special-online--live .special-online__link:after, .special-online--livestream .special-online__link:after, .special-online--waiting .special-online__link:after {
    margin-left: 5px;
  }
}

@media (min-width: 576px) {
  .special-online--live .special-online__link:after, .special-online--livestream .special-online__link:after {
    background-image: url("/images/blesk/dist/svg/online-broadcast-arrow-red.svg");
  }

  .special-online--waiting .special-online__link:after {
    background-image: url("/images/blesk/dist/svg/online-broadcast-pause-orange.svg");
  }
}

.special-online__link-href {
  display: none;
}

.special-online__link-href:hover, .special-online__link-href:active, .special-online__link-href:focus {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .special-online__link-href {
    color: #ed1d25;
    font-size: 13px;
    display: block;
  }

  .special-online--waiting .special-online__link-href {
    color: #f80;
  }
}

.slider {
  width: 320px;
  height: 68px;
  position: relative;
}

.slider__wrapper {
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.slider__inner {
  align-items: center;
  width: 9999px;
  height: 100%;
  display: flex;
  position: relative;
}

.slider__item {
  flex-shrink: 0;
  width: 120px;
  height: 100%;
  padding-right: 10px;
}

.slider__item img {
  max-width: 100%;
  height: auto;
}

.slider__button {
  z-index: 1;
  cursor: pointer;
  align-items: center;
  height: 100%;
  display: flex;
  position: absolute;
}

.slider__button:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0) #d0112b;
}

.slider__button--prev {
  left: 0;
}

.slider__button--prev:before {
  border-width: 6px 8px 6px 0;
}

.slider__button--next {
  right: 0;
}

.slider__button--next:before {
  border-width: 6px 0 6px 8px;
}

.special-slider {
  background-color: #000;
  margin: 0 -10px;
  padding: 10px;
  font-family: tablet_gothic_condensed, sans-serif;
}

@media (min-width: 576px) {
  .special-slider {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 960px) {
  .special-slider {
    padding-bottom: 15px;
  }
}

.special-slider__headline {
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 10px;
  font-size: 13px;
  display: flex;
}

@media (min-width: 576px) {
  .special-slider__headline {
    margin-bottom: 5px;
    font-size: 16px;
  }
}

.special-slider__headline:hover, .special-slider__headline:focus, .special-slider__headline:active {
  text-decoration: underline;
}

.special-slider__title {
  color: red;
  margin: 0 0 0 5px;
}

.slider {
  width: 100%;
}

@media (min-width: 576px) {
  .slider {
    height: 73px;
  }
}

@media (min-width: 960px) {
  .slider {
    height: 90px;
  }
}

@media (min-width: 576px) {
  .slider__wrapper {
    margin-left: 15px;
  }
}

@media (min-width: 768px) {
  .slider__wrapper {
    margin-right: 15px;
  }
}

@media (min-width: 960px) {
  .slider__wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.slider__item {
  box-sizing: content-box;
  min-width: 120px;
}

@media (min-width: 576px) {
  .slider__item {
    min-width: 130px;
    padding-right: 12px;
  }
}

@media (min-width: 960px) {
  .slider__item {
    min-width: 160px;
    padding-right: 20px;
  }
}

.slider__button--prev {
  background: linear-gradient(90deg, #040414 0%, rgba(4, 4, 20, 0) 100%);
  padding: 0 20px 0 10px;
}

@media (min-width: 576px) {
  .slider__button--prev {
    background: none;
    padding-left: 15px;
    padding-right: 35px;
    left: -15px;
  }
}

.slider__button--next {
  background: linear-gradient(90deg, rgba(4, 4, 20, 0) 0%, #040414 100%);
  padding: 0 10px 0 20px;
}

@media (min-width: 576px) {
  .slider__button--next {
    padding-left: 35px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .slider__button--next {
    background: none;
    right: -15px;
  }
}

.breaking-news-photo {
  background-color: #ffe600;
  margin: 0 -10px;
  padding: 10px;
  display: none;
}

@media (min-width: 576px) {
  .breaking-news-photo {
    flex-direction: row-reverse;
    margin-top: 20px;
  }
}

.breaking-news-photo--show {
  display: block;
}

@media (min-width: 576px) {
  .breaking-news-photo--show {
    display: flex;
  }
}

.breaking-news-photo__photo {
  position: relative;
}

@media (min-width: 576px) {
  .breaking-news-photo__photo {
    min-width: 295px;
  }
}

@media (min-width: 768px) {
  .breaking-news-photo__photo {
    min-width: 450px;
  }
}

@media (min-width: 960px) {
  .breaking-news-photo__photo {
    min-width: 620px;
  }
}

@media (min-width: 576px) {
  .breaking-news-photo__photo:before {
    content: "";
    transform-origin: top;
    background-color: #ffe600;
    width: 22%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: skewX(-22deg);
  }
}

.breaking-news-photo__header {
  color: #fff;
  white-space: nowrap;
  background-color: #16212d;
  padding: 8px 12px;
  font: italic bold 11px arial, sans-serif;
}

@media (min-width: 576px) {
  .breaking-news-photo__header {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }
}

@media (min-width: 960px) {
  .breaking-news-photo__header {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 14px;
  }
}

.breaking-news-photo__header--mobile {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  .breaking-news-photo__header--mobile {
    display: none;
  }
}

.breaking-news-photo__header--desktop {
  position: relative;
}

@media (max-width: 575px) {
  .breaking-news-photo__header--desktop {
    display: none;
  }
}

.breaking-news-photo__header:after {
  content: "";
  transform-origin: bottom;
  background-color: #16212d;
  width: 17px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: skewX(-22deg);
}

.breaking-news-photo__close {
  cursor: pointer;
  background-color: #16212d;
  border: 2px solid #ffe600;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  position: absolute;
  top: -4px;
  right: -4px;
}

@media (min-width: 576px) {
  .breaking-news-photo__close {
    border: none;
    width: 27px;
    height: 27px;
    top: -20px;
    right: -20px;
  }
}

@media (min-width: 768px) {
  .breaking-news-photo__close {
    width: 31px;
    height: 31px;
    top: -25px;
    right: -25px;
  }
}

@media (min-width: 960px) {
  .breaking-news-photo__close {
    width: 35px;
    height: 35px;
  }
}

.breaking-news-photo__close:before, .breaking-news-photo__close:after {
  content: "";
  background-color: #fff;
  width: 10px;
  height: 2px;
  position: absolute;
  top: 8px;
  left: 5px;
}

@media (min-width: 576px) {
  .breaking-news-photo__close:before, .breaking-news-photo__close:after {
    width: 18px;
    top: 12px;
  }
}

@media (min-width: 768px) {
  .breaking-news-photo__close:before, .breaking-news-photo__close:after {
    width: 19px;
    top: 14px;
    left: 6px;
  }
}

@media (min-width: 960px) {
  .breaking-news-photo__close:before, .breaking-news-photo__close:after {
    width: 23px;
    top: 16px;
  }
}

.breaking-news-photo__close:before {
  transform: rotate(45deg);
}

.breaking-news-photo__close:after {
  transform: rotate(-45deg);
}

.breaking-news-photo__text {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 576px) {
  .breaking-news-photo__text {
    z-index: 1;
    position: relative;
  }
}

.breaking-news-photo__title {
  color: #000;
  margin: 10px 0 0;
  font: 6.5vw tablet_gothic_condensed, sans-serif;
  text-decoration: none;
}

@media (min-width: 576px) {
  .breaking-news-photo__title {
    margin-top: 15px;
    margin-left: 5px;
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .breaking-news-photo__title {
    margin-top: 20px;
    font-size: 36px;
  }
}

@media (min-width: 960px) {
  .breaking-news-photo__title {
    margin-top: 30px;
    margin-left: 10px;
    font-size: 46px;
  }
}

.breaking-news-photo__title:hover, .breaking-news-photo__title:focus, .breaking-news-photo__title:active {
  text-decoration: underline;
}

.special {
  flex-direction: column;
  margin: 0 -10px -10px;
  padding: 10px;
  display: flex;
}

@media (min-width: 576px) {
  .special {
    margin-bottom: -20px;
  }
}

.special__button {
  color: #fff;
  align-items: center;
  margin: 10px auto 0;
  padding: 12px 20px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
}

@media (min-width: 768px) {
  .special__button {
    display: none;
  }
}

.special__button:after {
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: #fff;
  margin: 0 0 0 10px;
}

.activities {
  background-color: #e20b05;
  padding: 10px;
}

@media (min-width: 576px) {
  .activities {
    flex-flow: column wrap;
    justify-content: space-between;
    display: flex;
  }
}

@media (min-width: 768px) {
  .activities {
    padding: 20px;
  }
}

.activities__top {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 575px) {
  .activities__top {
    flex-direction: column;
  }
}

.activities__headline {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 575px) {
  .activities__headline {
    border-bottom: 1px solid #8e130f;
    margin-bottom: 10px;
    padding-bottom: 12px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .activities__headline {
    flex-direction: column;
    align-items: flex-start;
  }
}

.activities__blesk-claim {
  width: 135px;
  height: 20px;
  margin: 5px 0 0;
}

@media (min-width: 576px) {
  .activities__blesk-claim {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .activities__blesk-claim {
    width: 180px;
    height: 26px;
    margin-top: 5px;
    margin-left: 20px;
  }
}

.activities__logos {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.activities__logo-wrapper {
  position: relative;
}

@media (min-width: 576px) {
  .activities__logo-wrapper {
    margin-left: 20px;
  }
}

.activities__logo-wrapper:before {
  content: "";
  z-index: 1;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  transition: background-color .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.activities__logo-wrapper:hover:before, .activities__logo-wrapper:active:before, .activities__logo-wrapper:focus:before {
  background-color: #c90000;
}

.activities__logo {
  z-index: 2;
  width: 40px;
  position: relative;
}

.activities__logo--wider {
  width: 50px;
}

.activities__articles {
  margin: 20px 0 0;
}

@media (min-width: 576px) {
  .activities__articles {
    border-top: 1px solid #8e130f;
    justify-content: space-between;
    margin-top: 10px;
    padding-top: 15px;
    display: flex;
  }
}

@media (min-width: 768px) {
  .activities__articles {
    margin-top: 20px;
    padding-top: 20px;
  }
}

.activities__article {
  flex: 1 1 0;
}

@media (max-width: 575px) {
  .activities__article:nth-of-type(2) {
    display: none;
  }
}

@media (max-width: 959px) {
  .activities__article:nth-of-type(3) {
    display: none;
  }
}

.activities__article-link {
  color: #fff;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
}

.activities__article-link:hover, .activities__article-link:active, .activities__article-link:focus {
  text-decoration: underline;
}

.activities__article-image {
  flex: 0 0 120px;
}

@media (min-width: 576px) {
  .activities__article-image {
    flex-basis: 140px;
  }
}

.activities__article-title {
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: bold;
}

@media (min-width: 576px) {
  .activities__article-title {
    margin-left: 15px;
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .activities__article-title {
    font-size: 14px;
  }
}

@media (min-width: 960px) {
  .activities__article-title {
    font-size: 17px;
  }
}

@media (min-width: 576px) {
  .activities__article:first-of-type .activities__article-title, .activities__article:nth-of-type(2) .activities__article-title {
    padding-right: 10px;
  }
}

.weather {
  background-color: #fff;
  flex-direction: column;
  margin: 30px 0;
  padding: 10px 10px 15px;
  font-family: tablet_gothic_condensed, sans-serif;
  display: flex;
}

@media (min-width: 576px) {
  .weather {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .weather {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (min-width: 960px) {
  .weather {
    padding: 13px 20px 20px;
  }
}

.weather__top {
  justify-content: space-between;
  margin: 0 0 25px;
  display: flex;
}

@media (min-width: 576px) {
  .weather__top {
    margin-bottom: 20px;
  }
}

.weather__title {
  color: #171717;
  text-transform: uppercase;
  height: 30px;
  font-size: 20px;
  line-height: 41px;
  text-decoration: none;
}

@media (min-width: 576px) {
  .weather__title {
    font-size: 30px;
  }
}

.weather__title:hover, .weather__title:focus, .weather__title:active {
  text-decoration: underline;
}

.weather__link {
  color: #23aaff;
  align-self: flex-start;
  align-items: center;
  margin: 0;
  font: bold 13px arial, sans-serif;
  text-decoration: none;
  transition: background-color .3s ease-in-out;
  display: flex;
}

@media (min-width: 576px) and (max-width: 767px) {
  .weather__link {
    font-size: 14px;
  }
}

@media (min-width: 960px) {
  .weather__link {
    color: #fff;
    background-color: #23aaff;
    margin-top: -3px;
    margin-right: -10px;
    padding: 12px 20px;
  }
}

.weather__link:hover, .weather__link:focus, .weather__link:active {
  background-color: #0184d6;
}

.weather__link:after {
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: #23aaff;
  margin: 0 0 0 10px;
}

@media (min-width: 960px) {
  .weather__link:after {
    border-left-color: #fff;
  }
}

.weather__list-wrapper {
  position: relative;
}

@media (max-width: 959px) {
  .weather__list-wrapper:before {
    content: "";
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
    width: 30px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.weather__list {
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6 transparent;
  flex: 0 0 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  overflow-x: auto;
}

.weather__list::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 20px;
}

.weather__list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media (min-width: 768px) {
  .weather__list {
    padding-bottom: 0;
    overflow: hidden;
  }
}

.weather__item {
  border-right: 1px solid #cecece;
  flex-shrink: 0;
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
}

@media (min-width: 768px) {
  .weather__item {
    flex: auto;
    margin-right: 0;
    padding-right: 0;
  }
}

.weather__item:last-child {
  border: none;
  margin-right: 0;
}

.weather__block {
  color: #171717;
  align-items: center;
  height: 100%;
  text-decoration: none;
  display: flex;
}

@media (min-width: 768px) {
  .weather__block {
    justify-content: center;
  }
}

.weather__block:hover, .weather__block:focus, .weather__block:active {
  text-decoration: underline;
}

.weather__city {
  text-transform: uppercase;
  margin: 2px 10px 0 0;
  font-size: 15px;
}

@media (min-width: 960px) {
  .weather__city {
    font-size: 20px;
  }
}

@media (max-width: 959px) {
  .weather__image {
    width: 26px;
    height: 27px;
  }
}

.weather__temperature {
  margin: 2px 0 0 10px;
  font-size: 20px;
}

.horoscopes {
  background-color: #fff;
  flex-direction: column;
  margin: 20px 0;
  padding: 10px 10px 15px;
  display: flex;
}

@media (min-width: 576px) {
  .horoscopes {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media (min-width: 960px) {
  .horoscopes {
    padding: 20px 20px 25px;
  }
}

.horoscopes__top {
  justify-content: space-between;
  margin: 0 0 15px;
  display: flex;
}

@media (min-width: 576px) {
  .horoscopes__top {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .horoscopes__top {
    margin-bottom: 10px;
  }
}

.horoscopes__title {
  color: #8135f0;
  text-transform: uppercase;
  font: 20px tablet_gothic_condensed, sans-serif;
  text-decoration: none;
}

@media (min-width: 576px) {
  .horoscopes__title {
    font-size: 30px;
  }
}

.horoscopes__title:hover {
  text-decoration: underline;
}

.horoscopes__link {
  color: #8135f0;
  align-self: flex-start;
  align-items: center;
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .3s ease-in-out;
  display: flex;
}

@media (min-width: 576px) and (max-width: 767px) {
  .horoscopes__link {
    font-size: 14px;
  }
}

@media (min-width: 960px) {
  .horoscopes__link {
    color: #fff;
    background-color: #8135f0;
    margin-top: -10px;
    margin-right: -10px;
    padding: 12px 20px;
  }
}

.horoscopes__link:hover, .horoscopes__link:focus, .horoscopes__link:active {
  background-color: #fa6bc2;
}

.horoscopes__link:after {
  content: "";
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: #8135f0;
  margin: 0 0 0 10px;
}

@media (min-width: 960px) {
  .horoscopes__link:after {
    border-left-color: #fff;
  }
}

.horoscopes__signs-wrapper {
  position: relative;
}

@media (max-width: 959px) {
  .horoscopes__signs-wrapper:before {
    content: "";
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
    width: 30px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.horoscopes__signs {
  scrollbar-width: thin;
  scrollbar-color: #e6e6e6 transparent;
  flex: 0 0 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  overflow-x: auto;
}

.horoscopes__signs::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
  border-radius: 20px;
}

.horoscopes__signs::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

@media (min-width: 960px) {
  .horoscopes__signs {
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.horoscopes__sign {
  flex-shrink: 0;
  text-decoration: none;
}

@media (max-width: 959px) {
  .horoscopes__sign {
    padding-right: 50px;
  }
}

@media (max-width: 767px) {
  .horoscopes__sign {
    padding-right: 40px;
  }
}

@media (max-width: 575px) {
  .horoscopes__sign {
    padding-right: 30px;
  }
}

@media (max-width: 959px) {
  .horoscopes__sign:last-child {
    padding-right: 10px;
  }
}

.horoscopes__sign-link {
  color: #000;
  flex-direction: column;
  text-decoration: none;
  display: flex;
}

.horoscopes__sign-link:hover, .horoscopes__sign-link:active, .horoscopes__sign-link:focus {
  color: #fa6bc2;
  text-decoration: underline;
}

.horoscopes__sign-image {
  background: url("https://img2.cncenter.cz/images/blesk-horoskopy/sprites/signs/signs.webp") 0 -60px no-repeat;
  width: 40px;
  height: 60px;
}

.horoscopes__sign-image:hover {
  background-position-y: 0;
}

.horoscopes__sign:first-child .horoscopes__sign-image {
  background-position-x: -18px;
}

.horoscopes__sign:nth-child(2) .horoscopes__sign-image {
  background-position-x: -98px;
}

.horoscopes__sign:nth-child(3) .horoscopes__sign-image {
  background-position-x: -178px;
}

.horoscopes__sign:nth-child(4) .horoscopes__sign-image {
  background-position-x: -258px;
}

.horoscopes__sign:nth-child(5) .horoscopes__sign-image {
  background-position-x: -338px;
}

.horoscopes__sign:nth-child(6) .horoscopes__sign-image {
  background-position-x: -418px;
}

.horoscopes__sign:nth-child(7) .horoscopes__sign-image {
  background-position-x: -498px;
}

.horoscopes__sign:nth-child(8) .horoscopes__sign-image {
  background-position-x: -578px;
}

.horoscopes__sign:nth-child(9) .horoscopes__sign-image {
  background-position-x: -658px;
}

.horoscopes__sign:nth-child(10) .horoscopes__sign-image {
  background-position-x: -738px;
}

.horoscopes__sign:nth-child(11) .horoscopes__sign-image {
  background-position-x: -818px;
}

.horoscopes__sign:nth-child(12) .horoscopes__sign-image {
  background-position-x: -898px;
}

.horoscopes__sign-name {
  text-align: center;
  margin: 0;
  font-size: 13px;
}

.widget-tv-program {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .widget-tv-program {
    margin-bottom: 40px;
  }
}

@media (min-width: 1320px) {
  #cnc-out-of-page {
    display: none !important;
  }
}

.page-content {
  margin: 0 auto;
}

@media (min-width: 1320px) {
  .page-content {
    justify-content: center;
    max-width: 1600px;
    padding: 0 10px;
    display: flex;
  }
}

@media (min-width: 1540px) {
  .page-content__left {
    margin-right: auto;
  }
}

.page-content__right {
  width: 600px;
  margin: 20px 0 0 20px;
  position: relative;
}

@media (max-width: 1540px) {
  .page-content__right {
    margin-left: 10px;
  }
}

@media (max-width: 1319px) {
  .page-content__right {
    display: none;
  }
}

.page-content__ear-link {
  display: block;
  position: relative;
}

.page-content__ear-link[data-ear="fixed"] {
  position: fixed;
  top: 0;
}

.page-content__ear-link[data-centered] {
  top: 50%;
  transform: translateY(-50%);
}

@supports (position: sticky) {
  .page-content__ear-link[data-ear="sticky"] {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
  }
}

.page-content__ear-image {
  width: calc(100vw - 1000px);
  min-width: 300px;
  max-width: 600px;
  display: block;
  position: relative;
}

@media (max-width: 1540px) {
  .page-content__ear-image {
    width: calc(100vw - 990px);
  }
}

.page-content__ear-sticker {
  z-index: 2;
  color: #000;
  background-color: #e8e8e8;
  border-radius: 3px;
  padding: 2px 4px;
  font-family: sans-serif;
  font-size: 10px;
  line-height: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.videobox {
  color: #fff;
  background: #fff;
  padding: 10px;
}

.videobox .article-preview__title:hover {
  color: #000;
}

.videobox .article-preview {
  height: 100%;
}

.videobox .article-preview__sticker {
  display: none;
}

.videobox__articles {
  justify-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

.videobox__article {
  width: 32.6%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 575px) {
  .videobox__articles {
    display: block;
  }

  .videobox__article {
    width: 100%;
    margin-bottom: 10px;
  }

  .videobox {
    margin: 0 -10px;
  }

  .videobox .article-preview {
    background: none;
    flex-direction: row;
  }

  .videobox .article-preview__top {
    flex-shrink: 0;
    width: 50%;
  }

  .videobox .article-preview__title {
    color: #000;
  }

  .videobox .videobox__article:last-child {
    margin-bottom: 0;
  }

  .videobox .article-preview__image {
    min-height: auto;
  }
}

.mostread-articles-premium {
  color: #fff;
  background: #fff;
  padding: 10px;
}

.mostread-articles-premium .article-preview__title:hover {
  color: #000;
}

.mostread-articles-premium .article-preview {
  height: 100%;
}

.mostread-articles-premium .article-preview__sticker {
  display: none;
}

.mostread-articles-premium__articles {
  justify-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

.mostread-articles-premium__article {
  width: 32.6%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 575px) {
  .mostread-articles-premium__articles {
    display: block;
  }

  .mostread-articles-premium__article {
    width: 100%;
    margin-bottom: 10px;
  }

  .mostread-articles-premium {
    margin: 0 -10px;
  }

  .mostread-articles-premium .article-preview {
    background: none;
    flex-direction: row;
  }

  .mostread-articles-premium .article-preview__top {
    flex-shrink: 0;
    width: 50%;
  }

  .mostread-articles-premium .article-preview__title {
    color: #000;
  }

  .mostread-articles-premium .mostread-articles-premium__article:last-child {
    margin-bottom: 0;
  }

  .mostread-articles-premium .article-preview__image {
    min-height: auto;
  }
}

.promobox-carousel {
  height: 380px;
  margin-bottom: 20px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .promobox-carousel {
    height: 302px;
  }
}

@media (max-width: 575px) {
  .promobox-carousel {
    height: 164px;
  }
}

.promobox-carousel .glide__track {
  height: 380px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .promobox-carousel .glide__track {
    height: 302px;
  }
}

@media (max-width: 575px) {
  .promobox-carousel .glide__track {
    height: 164px;
  }
}

.promobox-carousel .glide__arrows {
  pointer-events: none;
  width: 100%;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
}

@media (max-width: 767px) {
  .promobox-carousel .glide__arrows {
    height: 80px;
    top: calc(50% - 40px);
  }
}

@media (max-width: 575px) {
  .promobox-carousel .glide__arrows {
    height: 60px;
    top: calc(50% - 30px);
  }
}

.promobox-carousel .glide__arrow {
  z-index: 0;
  color: #fff;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  background: rgba(0, 0, 0, .6);
  border: 0;
  width: 40px;
  height: 100%;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .promobox-carousel .glide__arrow {
    width: 20px;
  }
}

.promobox-carousel .glide__arrow--left {
  left: 0;
}

.promobox-carousel .glide__arrow--right {
  right: 0;
}

.promobox-carousel .glide__arrow-left-icon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -950px -450px no-repeat;
  border-left: 5px solid rgba(0, 0, 0, 0);
  width: 17px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: calc(50% - 35px);
  bottom: 0;
  left: -8px;
  right: 0;
}

@media screen and (max-width: 768px) {
  .promobox-carousel .glide__arrow-left-icon {
    top: calc(50% - 40px);
  }
}

@media (max-width: 575px) {
  .promobox-carousel .glide__arrow-left-icon {
    top: calc(50% - 25px);
  }
}

.promobox-carousel .glide__arrow-right-icon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -1000px -450px no-repeat;
  border-left: 3px solid rgba(0, 0, 0, 0);
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: calc(50% - 35px);
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .promobox-carousel .glide__arrow-right-icon {
    top: calc(50% - 40px);
  }
}

@media (max-width: 575px) {
  .promobox-carousel .glide__arrow-right-icon {
    top: calc(50% - 25px);
  }
}

.promobox-carousel .glide__bullets {
  text-align: center;
  background-color: rgba(0, 0, 0, .5);
  width: 100%;
  height: 6px;
  display: block;
  position: absolute;
  bottom: 0;
}

.promobox-carousel .glide__bullet {
  float: left;
  background-color: rgba(40, 40, 40, .5);
  border: 0;
  width: 100%;
  height: 6px;
  margin: 0;
  padding: 0;
  display: flex;
}

.promobox-carousel .glide__bullet--active {
  background-color: rgba(237, 28, 36, .8);
}

.promobox-carousel .glide__bullet--two {
  width: 50%;
}

.promobox-carousel .glide__bullet--three {
  width: 33.333%;
}

.promobox-carousel--zpravy .glide__bullet--active {
  background-color: #0d69d9;
}

.promobox-carousel--celebrity .glide__bullet--active {
  background-color: red;
}

.promobox-carousel--sport .glide__bullet--active {
  background-color: #04a545;
}

.promobox-carousel--style .glide__bullet--active {
  background-color: #cb2f9b;
}

.promobox-carousel--video .glide__bullet--active {
  background-color: gray;
}

